export const GET_NATURA2000_SDF = 'GET_NATURA2000_SDF';

export function getNatura2000SDF(code, year) {
  return {
    type: GET_NATURA2000_SDF,
    request: {
      op: 'get',
      path: `/@natura2000/` + code + (year ? '?anno=' + year : ''),
    },
  };
}
