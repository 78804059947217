import React from 'react';

const ConservationStatus = ({ data }) => {
  return data.iucn ? (
    <div className={`conservation-status ${data.iucn.id}`}>
      <div className="code">{data.iucn.id}</div>
      <div className="label">{data.iucn.label}</div>
    </div>
  ) : null;
};

export default ConservationStatus;
