/**
 * Root reducer.
 * @module reducers/root
 */
import { getCatalogDataReducer } from './getCatalogDataReducer';
import { searchAutocompleteReducer } from './searchAutocompleteReducer';
import { getSitiNatura2000Reducer } from './getSitiNatura2000Reducer';
import { getWatchings } from './getWatchings';
import { getPollNetDataReducer } from './getPollNetDataReducer';
import { getCitizenScienceDataReducer } from './getCitizenScienceDataReducer';
import { getNatura2000SDFReducer } from './getNatura2000SDFReducer';
import { getArt12Reducer } from './getArt12Reducer';
import defaultReducers from '@italia/reducers';

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  // Add your reducers here
  catalogData: getCatalogDataReducer,
  searchAutocomplete: searchAutocompleteReducer,
  sitiNatura2000: getSitiNatura2000Reducer,
  watchings: getWatchings,
  pollnetData: getPollNetDataReducer,
  citizenScienceData: getCitizenScienceDataReducer,
  natura2000SDF: getNatura2000SDFReducer,
  art12: getArt12Reducer,
};

export default reducers;
