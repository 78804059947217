import React from 'react';
import { viewValue, isEmpty } from '../utils';

const Art12_1_SpeciesInformation = ({ data }) => {
  const empty =
    isEmpty(data.country) &&
    isEmpty(data.speciescode) &&
    isEmpty(data.euringcode) &&
    isEmpty(data.speciesname) &&
    isEmpty(data.sub_unit) &&
    isEmpty(data.alternative_speciesname) &&
    isEmpty(data.common_speciesname) &&
    isEmpty(data.season);
  return !empty ? (
    <div className="art12-section">
      <h2>1. Species information</h2>
      <table className="art12-table">
        <tbody>
          <tr>
            <th>1.1 Member State</th>
            <td className="bold">{viewValue(data.country)}</td>
          </tr>
          <tr>
            <th>1.2 Species Code</th>
            <td className="highlited">{viewValue(data.speciescode)}</td>
          </tr>
          <tr>
            <th>1.3 EURING code</th>
            <td>{viewValue(data.euringcode)}</td>
          </tr>
          <tr>
            <th>1.4 Species scientific name</th>
            <td className="highlited">{viewValue(data.speciesname)}</td>
          </tr>
          <tr>
            <th>1.5 Subspecific population</th>
            <td>{viewValue(data.sub_unit)}</td>
          </tr>
          <tr>
            <th>
              1.6 Alternative species scientific name{' '}
              <span className="right">(Optional)</span>
            </th>
            <td>{viewValue(data.alternative_speciesname)}</td>
          </tr>
          <tr>
            <th>
              1.7 Common Name <span className="right">(Optional)</span>
            </th>
            <td>{viewValue(data.common_speciesname)}</td>
          </tr>
          <tr>
            <th>1.8 Season</th>
            <td className="highlited">{viewValue(data.season)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
};

export default Art12_1_SpeciesInformation;
