export const GET_POLLNET_DATA = 'GET_POLLNET_DATA';

export function getPollNetData(url) {
  return {
    type: GET_POLLNET_DATA,
    subrequest: url,
    request: {
      op: 'get',
      path: `${url}`,
    },
  };
}
