/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import SDFHeader from '@package/components/Views/Natura2000/SDF/parts/SDFHeader';
import SDFTitle from '@package/components/Views/Natura2000/SDF/parts/SDFTitle';
import SDFToc from '@package/components/Views/Natura2000/SDF/parts/SDFToc';
import SDFPrint from '@package/components/Views/Natura2000/SDF/parts/SDFPrint';
import SDFSection from '@package/components/Views/Natura2000/SDF/parts/SDFSection';

import SDF1_1 from '@package/components/Views/Natura2000/SDF/parts/SDF1/SDF1_1';
import SDF1_2 from '@package/components/Views/Natura2000/SDF/parts/SDF1/SDF1_2';
import SDF1_3 from '@package/components/Views/Natura2000/SDF/parts/SDF1/SDF1_3';
import SDF1_4 from '@package/components/Views/Natura2000/SDF/parts/SDF1/SDF1_4';
import SDF1_5 from '@package/components/Views/Natura2000/SDF/parts/SDF1/SDF1_5';
import SDF1_6 from '@package/components/Views/Natura2000/SDF/parts/SDF1/SDF1_6';
import SDF1_7 from '@package/components/Views/Natura2000/SDF/parts/SDF1/SDF1_7';

import SDF2_1 from '@package/components/Views/Natura2000/SDF/parts/SDF2/SDF2_1';
import SDF2_2 from '@package/components/Views/Natura2000/SDF/parts/SDF2/SDF2_2';
import SDF2_3 from '@package/components/Views/Natura2000/SDF/parts/SDF2/SDF2_3';
import SDF2_4 from '@package/components/Views/Natura2000/SDF/parts/SDF2/SDF2_4';
import SDF2_5 from '@package/components/Views/Natura2000/SDF/parts/SDF2/SDF2_5';
import SDF2_6 from '@package/components/Views/Natura2000/SDF/parts/SDF2/SDF2_6';

import SDF3_1 from '@package/components/Views/Natura2000/SDF/parts/SDF3/SDF3_1';
import SDF3_2 from '@package/components/Views/Natura2000/SDF/parts/SDF3/SDF3_2';
import SDF3_3 from '@package/components/Views/Natura2000/SDF/parts/SDF3/SDF3_3';

import SDF4_1 from '@package/components/Views/Natura2000/SDF/parts/SDF4/SDF4_1';
import SDF4_2 from '@package/components/Views/Natura2000/SDF/parts/SDF4/SDF4_2';
import SDF4_3 from '@package/components/Views/Natura2000/SDF/parts/SDF4/SDF4_3';
import SDF4_4 from '@package/components/Views/Natura2000/SDF/parts/SDF4/SDF4_4';
import SDF4_5 from '@package/components/Views/Natura2000/SDF/parts/SDF4/SDF4_5';

import SDF5_1 from '@package/components/Views/Natura2000/SDF/parts/SDF5/SDF5_1';
import SDF5_2 from '@package/components/Views/Natura2000/SDF/parts/SDF5/SDF5_2';

import SDF6_1 from '@package/components/Views/Natura2000/SDF/parts/SDF6/SDF6_1';
import SDF6_2 from '@package/components/Views/Natura2000/SDF/parts/SDF6/SDF6_2';

import SDF7 from '@package/components/Views/Natura2000/SDF/parts/SDF7/SDF7';

const SDF = ({ data }) => {
  return data && Object.keys(data)?.length > 0 ? (
    <div className="sdf">
      <SDFHeader data={data} />

      <div className="sdf-body">
        <SDFTitle data={data} />

        <SDFToc data={data} />

        <SDFPrint data={data} />

        <SDFSection id={1} title="1. SITE IDENTIFICATION">
          <SDF1_1 data={data} />
          <SDF1_2 data={data} />
          <SDF1_3 data={data} />
          <SDF1_4 data={data} />
          <SDF1_5 data={data} />
          <SDF1_6 data={data} />
          <SDF1_7 data={data} />
        </SDFSection>

        <SDFSection id={2} title="2. SITE LOCATION">
          <SDF2_1 data={data} />
          <SDF2_2 data={data} />
          <SDF2_3 data={data} />
          <SDF2_4 data={data} />
          <SDF2_5 data={data} />
          <SDF2_6 data={data} />
        </SDFSection>

        <SDFSection id={3} title="3. ECOLOGICAL INFORMATION">
          <SDF3_1 data={data} />
          <SDF3_2 data={data} />
          <SDF3_3 data={data} />
        </SDFSection>

        <SDFSection id={4} title="4. SITE DESCRIPTION">
          <SDF4_1 data={data} />
          <SDF4_2 data={data} />
          <SDF4_3 data={data} />
          <SDF4_4 data={data} />
          <SDF4_5 data={data} />
        </SDFSection>

        <SDFSection id={5} title="5. SITE PROTECTION STATUS">
          <SDF5_1 data={data} />
          <SDF5_2 data={data} />
        </SDFSection>

        <SDFSection id={6} title="6. SITE MANAGEMENT">
          <SDF6_1 data={data} />
          <SDF6_2 data={data} />
          <p style={{ 'page-break-after': 'always' }}>&nbsp;</p>
        </SDFSection>

        <SDFSection id={7} title="7. MAP OF THE SITE">
          <SDF7 data={data} />
        </SDFSection>
      </div>
    </div>
  ) : null;
};

export default SDF;
