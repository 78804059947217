export const GET_WATCHINGS = 'GET_WATCHINGS';

export function getWatchings(url) {
  return {
    type: GET_WATCHINGS,
    subrequest: url,
    request: {
      op: 'get',
      path: `${url}`,
    },
  };
}
