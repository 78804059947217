import React from 'react';
import { viewValue, isEmpty } from '../utils';

const Art12_5_BreedingDistributionTrend = ({ data }) => {
  const empty =
    isEmpty(data.distribution_trend_period) &&
    isEmpty(data.distribution_trend) &&
    isEmpty(data.distribution_trend_magnitude_min) &&
    isEmpty(data.distribution_trend_magnitude_max) &&
    isEmpty(data.distribution_trend_magnitude) &&
    isEmpty(data.distribution_trend_method) &&
    isEmpty(data.distribution_trend_source) &&
    isEmpty(data.distribution_trend_long_period) &&
    isEmpty(data.distribution_trend_long) &&
    isEmpty(data.distribution_trend_long_magnitude_min) &&
    isEmpty(data.distribution_trend_long_magnitude_max) &&
    isEmpty(data.distribution_trend_long_magnitude) &&
    isEmpty(data.distribution_trend_long_method) &&
    isEmpty(data.distribution_trend_long_source) &&
    isEmpty(data.distribution_trend_info);
  return !empty ? (
    <div className="art12-section">
      <h2>5. Breeding distribution trend</h2>
      <table className="art12-table">
        <tbody>
          <tr>
            <th className="header-3" colSpan={2}>
              5.1 Short-term trend (last 12 years)
            </th>
          </tr>
          <tr>
            <th>5.1.1 Short-term trend Period</th>
            <td>{viewValue(data.distribution_trend_period)}</td>
          </tr>
          <tr>
            <th>5.1.2 Short-term trend Direction</th>
            <td>{viewValue(data.distribution_trend)}</td>
          </tr>
          <tr>
            <th>5.1.3a Short-term trend Magnitude - Minimum</th>
            <td>{viewValue(data.distribution_trend_magnitude_min)}</td>
          </tr>
          <tr>
            <th>5.1.3b Short-term trend Magnitude - Maximum</th>
            <td>{viewValue(data.distribution_trend_magnitude_max)}</td>
          </tr>
          <tr>
            <th>5.1.3c Short-term trend Magnitude - Best single value</th>
            <td>{viewValue(data.distribution_trend_magnitude)}</td>
          </tr>
          <tr>
            <th>5.1.4 Short-term trend Method used</th>
            <td>{viewValue(data.distribution_trend_method)}</td>
          </tr>
          <tr>
            <th>5.1.5 Sources</th>
            <td>{viewValue(data.distribution_trend_source)}</td>
          </tr>
          <tr>
            <th colSpan={2} className="header-3">
              5.2 Long-term trend (since c. 1980)
            </th>
          </tr>
          <tr>
            <th>5.2.1 Long-term trend Period</th>
            <td>{viewValue(data.distribution_trend_long_period)}</td>
          </tr>
          <tr>
            <th>5.2.2 Long-term trend Direction</th>
            <td>{viewValue(data.distribution_trend_long)}</td>
          </tr>
          <tr>
            <th>5.2.3a Long-term trend Magnitude - Minimum</th>
            <td>{viewValue(data.distribution_trend_long_magnitude_min)}</td>
          </tr>
          <tr>
            <th>5.2.3b Long-term trend Magnitude - Maximum</th>
            <td>{viewValue(data.distribution_trend_long_magnitude_max)}</td>
          </tr>
          <tr>
            <th>5.2.3c Long-term trend Magnitude - Best single value</th>
            <td>{viewValue(data.distribution_trend_long_magnitude)}</td>
          </tr>
          <tr>
            <th>5.2.4 Long-term trend Method used</th>
            <td>{viewValue(data.distribution_trend_long_method)}</td>
          </tr>
          <tr>
            <th>5.2.5 Sources</th>
            <td>{viewValue(data.distribution_trend_long_source)}</td>
          </tr>
          <tr>
            <th>
              5.3 Additional Information
              <span className="right">(Optional)</span>
            </th>
            <td>{viewValue(data.distribution_trend_info)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
};

export default Art12_5_BreedingDistributionTrend;
