import React from 'react';
import { viewValue, isEmpty } from '../utils';

const Art12_9_Natura2000SpasCoverage = ({ data }) => {
  const empty =
    isEmpty(data.spa_population_unit) &&
    isEmpty(data.spa_population_min) &&
    isEmpty(data.spa_population_max) &&
    isEmpty(data.spa_population) &&
    isEmpty(data.spa_population_estimate_type) &&
    isEmpty(data.spa_population_method) &&
    isEmpty(data.spa_population_trend) &&
    isEmpty(data.spa_population_trend_method) &&
    isEmpty(data.spa_info);

  return !empty ? (
    <div className="art12-section">
      <h2>9. Natura 2000 (SPAs) coverage</h2>
      <table className="art12-table">
        <tbody>
          <tr>
            <th>
              9.1a Population size inside the Natura 2000 (SPA) network - Unit
            </th>
            <td>{viewValue(data.spa_population_unit)}</td>
          </tr>
          <tr>
            <th>
              9.1b Population size inside the Natura 2000 (SPA) network -
              Minimum
            </th>
            <td>{viewValue(data.spa_population_min)}</td>
          </tr>
          <tr>
            <th>
              9.1c Population size inside the Natura 2000 (SPA) network -
              Maximum
            </th>
            <td>{viewValue(data.spa_population_max)}</td>
          </tr>
          <tr>
            <th>
              9.1d Population size inside the Natura 2000 (SPA) network - Best
              single value
            </th>
            <td>{viewValue(data.spa_population)}</td>
          </tr>
          <tr>
            <th>9.2 Type of estimate</th>
            <td>{viewValue(data.spa_population_estimate_type)}</td>
          </tr>
          <tr>
            <th>9.3 Population size inside the network Method used</th>
            <td>{viewValue(data.spa_population_method)}</td>
          </tr>
          <tr>
            <th>
              9.4 Short-term trend of population size within the network
              Direction
            </th>
            <td>{viewValue(data.spa_population_trend)}</td>
          </tr>
          <tr>
            <th>
              9.5 Short-term trend of population size within the network Method
              used
            </th>
            <td>{viewValue(data.spa_population_trend_method)}</td>
          </tr>
          <tr>
            <th>
              9.6 Additional Information{' '}
              <span className="right">(Optional)</span>
            </th>
            <td>{viewValue(data.spa_info)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
};

export default Art12_9_Natura2000SpasCoverage;
