/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Spinner,
  Button,
} from 'design-react-kit/dist/design-react-kit';
import { Helmet } from '@plone/volto/helpers';

import { getNatura2000SDF } from '@package/actions';

import ViewError from '@package/components/Views/Catalog/ViewError';
import SDF from '@package/components/Views/Natura2000/SDF/SDF';

const messages = defineMessages({
  basionym: {
    id: 'Basionym',
    defaultMessage: 'Basionym',
  },
  scientificName: {
    id: 'Scientific name',
    defaultMessage: 'Scientific name',
  },
  infraspeces: {
    id: 'Infraspeces',
    defaultMessage: 'Infraspeces',
  },
  classification: {
    id: 'Classification',
    defaultMessage: 'Classification',
  },

  downloadBiocaseFormat: {
    id: 'downloadBiocaseFormat',
    defaultMessage: 'Download dati nel formato Biocase',
  },
  viewGbifInfo: {
    id: 'viewGbifInfo',
    defaultMessage: 'Vedi le informazioni sul sito GBIF',
  },
  geoviewer_link: {
    id: 'Geoviewer link',
    defaultMessage: 'Vedi sulla mappa',
  },
  loading: {
    id: 'Catalog_loading',
    defaultMessage: 'Sto caricando i dati...',
  },
  goBack: { id: 'SDF_goBack', defaultMessage: 'Torna alla pagina precedente' },
});

const SDFView = (props) => {
  const intl = useIntl();
  moment.locale(intl.locale);
  const dispatch = useDispatch();
  const history = useHistory();
  const code = props?.match?.params?.[1];
  const [viewYear, setViewYear] = useState(null);

  const data = useSelector((state) => {
    return state.natura2000SDF?.result;
  });

  const natura2000SDF = useSelector((state) => {
    return state.natura2000SDF;
  });

  useEffect(() => {
    dispatch(getNatura2000SDF(code));
  }, [code]);

  useEffect(() => {
    dispatch(getNatura2000SDF(code, viewYear));
  }, [viewYear]);

  return (
    <div id="page-view-natura2000-sdf" className="ui container">
      {natura2000SDF.hasError ? (
        <ViewError error={natura2000SDF.error} />
      ) : (
        <>
          <Helmet title={data.canonicalName} />
          {natura2000SDF.loadingResults ? (
            <Container>
              <div className="mt-5 mb-5 loading">
                <Spinner active double={false} small={false} tag="span" />
                <div>{intl.formatMessage(messages.loading)}</div>
              </div>
            </Container>
          ) : (
            <>
              {/* -----Testata-----
              <div className="full-width PageHeaderWrapper pt-5 pb-2 mb-5">
                <Container className="px-3 px-md-4 mb-4">
                  <div className="title-description-wrapper">
                    <h1>SDF</h1>
                  </div>
                </Container>
              </div> */}

              <Container className="data-content">
                {data.years?.length > 0 && (
                  <div className="year-select-wrapper">
                    <div className="bootstrap-select-wrapper">
                      <label htmlFor="selectExampleClassic">
                        Database release:
                      </label>
                      <select
                        name="year"
                        value={viewYear || data.years[0]}
                        onChange={(e) => {
                          setViewYear(e.target.value);
                        }}
                        className="form-control"
                      >
                        {data.years.map((y) => (
                          <option value={y} key={y}>
                            {moment(y).format('DD/MM/YYYY')}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
                <SDF data={data} />
                <div className="back-button">
                  <Button
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    {intl.formatMessage(messages.goBack)}
                  </Button>
                </div>
              </Container>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SDFView;
