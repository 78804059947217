import React from 'react';
import { viewValue, isEmpty, getObjectProps } from '../utils';

const Art12_2_PopulationSize = ({ data }) => {
  const population_reasons_for_change = getObjectProps(
    data.population_reasons_for_change,
  );
  const empty =
    isEmpty(data.population_date) &&
    isEmpty(data.population_size_unit) &&
    isEmpty(data.population_size_min) &&
    isEmpty(data.population_size_max) &&
    isEmpty(data.population_size) &&
    isEmpty(data.population_estimate_type) &&
    isEmpty(data.population_method) &&
    isEmpty(data.population_sources) &&
    isEmpty(population_reasons_for_change?.no_change) &&
    isEmpty(population_reasons_for_change?.genuine) &&
    isEmpty(population_reasons_for_change?.knowledge) &&
    isEmpty(population_reasons_for_change?.method) &&
    isEmpty(population_reasons_for_change?.no_info) &&
    isEmpty(population_reasons_for_change?.main_reason) &&
    isEmpty(data.population_info);
  return !empty ? (
    <div className="art12-section">
      <h2>2. Population size</h2>
      <table className="art12-table">
        <tbody>
          <tr>
            <th>2.1 Year or Period</th>
            <td>{viewValue(data.population_date)}</td>
          </tr>
          <tr>
            <th>2.2a Population size - Unit</th>
            <td>{viewValue(data.population_size_unit)}</td>
          </tr>
          <tr>
            <th>2.2b Population size - Minimum</th>
            <td>{viewValue(data.population_size_min)}</td>
          </tr>
          <tr>
            <th>2.2c Population size - Maximum</th>
            <td>{viewValue(data.population_size_max)}</td>
          </tr>
          <tr>
            <th>2.2d Population size - Best single value</th>
            <td>{viewValue(data.population_size)}</td>
          </tr>
          <tr>
            <th>2.3 Population type of estimate</th>
            <td>{viewValue(data.population_estimate_type)}</td>
          </tr>
          <tr>
            <th>2.4 Population size Method used</th>
            <td>{viewValue(data.population_method)}</td>
          </tr>
          <tr>
            <th>2.5 Population Sources</th>
            <td className="highlited">{viewValue(data.population_sources)}</td>
          </tr>
          <tr>
            <th>2.6 Change and reason for change (since previous report)</th>
            <td className="no-padding">
              <table>
                <tr>
                  <td>Is there a change between reporting periods?</td>
                  <td>
                    {viewValue({
                      ...(population_reasons_for_change?.no_change ?? {}),
                      text:
                        population_reasons_for_change?.no_change?.text ===
                        'false'
                          ? 'true'
                          : 'false',
                    })}
                  </td>
                </tr>
                <tr>
                  <td>a) yes, due to genuine change</td>
                  <td>{viewValue(population_reasons_for_change?.genuine)}</td>
                </tr>
                <tr>
                  <td>b) yes, due to improved knowledge/more accurate data</td>
                  <td>{viewValue(population_reasons_for_change?.knowledge)}</td>
                </tr>
                <tr>
                  <td>c) yes, due to the use of different method</td>
                  <td>{viewValue(population_reasons_for_change?.method)}</td>
                </tr>
                <tr>
                  <td>
                    yes, but there is no information on the nature of change
                  </td>
                  <td>{viewValue(population_reasons_for_change?.no_info)}</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    The change is mainly due to:
                    <br />
                    {viewValue(population_reasons_for_change?.main_reason)}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <th>
              2.7 Additional information
              <span className="right">(Optional)</span>
            </th>
            <td>{viewValue(data.population_info)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
};

export default Art12_2_PopulationSize;
