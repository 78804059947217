import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Accordion } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { TextWidget } from '@plone/volto/components';
import ObjectBrowserWidget from '@plone/volto/components/manage/Widgets/ObjectBrowserWidget';

const messages = defineMessages({
  title: {
    id: 'SearchServicesTitle',
    defaultMessage: 'Titolo',
  },
  link: {
    id: 'link',
    defaultMessage: 'Collegamento',
  },
  desc: {
    id: 'desc',
    defaultMessage: 'Link rapidi',
  },

  search_service_links: {
    id: 'search_service_links',
    defaultMessage: 'Collegamenti',
  },
  helpText: {
    id: 'Help text',
    defaultMessage: 'Testo di aiuto',
  },
  backgroundImage: {
    id: 'Background image',
    defaultMessage: 'Immagine di sfondo',
  },
  searchCatalog: {
    id: 'Ricerca nel catalogo animali',
    defaultMessage: 'Ricerca nel catalogo animali',
  },
});

const Sidebar = ({
  block,
  data,
  onChangeBlock,
  sections,
  openObjectBrowser,
  required,
}) => {
  const intl = useIntl();

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>{intl.formatMessage(messages.searchCatalog)}</h2>
      </header>
      <Accordion className="form">
        <Accordion.Content active={true}>
          <TextWidget
            id="title"
            title={intl.formatMessage(messages.title)}
            required={true}
            value={data.title}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                [name]: value,
              });
            }}
          />
          <TextWidget
            id="placeholder"
            title={intl.formatMessage(messages.helpText)}
            required={true}
            value={data.placeholder}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                [name]: value,
              });
            }}
          />
          <ObjectBrowserWidget
            id="bgImage"
            title={intl.formatMessage(messages.backgroundImage)}
            value={data.bgImage || []}
            widgetOptions={{
              pattern_options: { selectableTypes: ['Image'] },
            }}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                [name]: value,
              });
            }}
          />
        </Accordion.Content>
      </Accordion>
      <Accordion fluid styled className="form">
        <Accordion.Title active={true} index={0} onClick={() => {}}>
          {intl.formatMessage(messages.search_service_links)}
        </Accordion.Title>
        <Accordion.Content active={true}>
          <ObjectBrowserWidget
            id="links"
            title={intl.formatMessage(messages.desc)}
            required={false}
            mode={'multiple'}
            value={data.links || []}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                [name]: value,
              });
            }}
          />
        </Accordion.Content>
      </Accordion>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  block: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default Sidebar;
