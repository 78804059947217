import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const messages = defineMessages({
  imagesFromGBIF: {
    id: 'imagesFromGBIF',
    defaultMessage: 'Immagini da GBIF',
  },
  imagesFromWikipedia: {
    id: 'imagesFromWikipedia',
    defaultMessage: 'Immagini da Wikipedia',
  },
  author: {
    id: 'images_author',
    defaultMessage: 'Autore',
  },
});

const Images = ({ images }) => {
  const intl = useIntl();

  const getSettings = (nItems, slideToScroll) => {
    return {
      adaptiveHeight: false,
      variableWidth: false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: nItems < 2 ? nItems : 2,
      slidesToScroll: slideToScroll ?? 2,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: nItems < 2 ? nItems : 2,
            slidesToScroll: nItems < 2 ? nItems : slideToScroll ?? 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: slideToScroll < 2 ? slideToScroll : 2,
            slidesToScroll: nItems < 2 ? nItems : slideToScroll ?? 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  };

  const drawSlider = (title, list) => {
    return (
      <div
        className={`it-carousel-wrapper it-carousel-landscape-abstract-three-cols`}
      >
        <div className="slider-container">
          <div className="it-header-block">
            <div className="it-header-block-title">
              <h5>{title}</h5>
            </div>
          </div>
          <div className="it-carousel-all it-card-bg">
            <Slider {...getSettings(list.length)}>
              {list.map((item, i) => (
                <div className="it-single-slide-wrapper" key={item.url}>
                  <figure>
                    <img src={item.url} alt="" className="img-fluid" />
                    {item.owner && (
                      <div className="description">
                        {intl.formatMessage(messages.author)}: {item.owner}
                      </div>
                    )}
                  </figure>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  };
  return images?.gbif?.length > 0 || images?.wikipedia?.length > 0 ? (
    <div className="images">
      {images.gbif?.length > 0 && (
        <div className="mb-5">
          {drawSlider(intl.formatMessage(messages.imagesFromGBIF), images.gbif)}
        </div>
      )}
      {images.wikipedia?.length > 0 && (
        <div className="mb-5">
          {drawSlider(
            intl.formatMessage(messages.imagesFromWikipedia),
            images.wikipedia,
          )}
        </div>
      )}
    </div>
  ) : null;
};

export default Images;
