/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDF2_1 = ({ data }) => {
  return (
    <>
      <h2>2.1 Site-centre location [decimal degrees]:</h2>
      <table className="SDFtableNoBorder MinimalHeight">
        <tbody>
          <tr>
            <td width="22%" className="Bold DivisorRight">
              Longitude:
            </td>
            <td>{Number(data.site_longitude || 0).toFixed(6)}</td>
          </tr>
          <tr>
            <td width="22%" className="Bold DivisorRight">
              Latitude:
            </td>
            <td>{Number(data.site_latitude || 0).toFixed(6)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SDF2_1;
