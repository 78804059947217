import React from 'react';
import { viewValue, isEmpty } from '../utils';

const Art12_10_InfoAnnexII = ({ data }) => {
  const empty =
    isEmpty(data.data_birds_annexII?.nationally_hunted) &&
    isEmpty(data.data_birds_annexII?.huntingbag_unit) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y1_min) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y2_min) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y3_min) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y4_min) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y5_min) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y6_min) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y1_max) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y2_max) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y3_max) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y4_max) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y5_max) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y6_max) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y1_unknown) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y2_unknown) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y3_unknown) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y4_unknown) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y5_unknown) &&
    isEmpty(data.data_birds_annexII?.huntingbag_y6_unknown) &&
    isEmpty(data.data_birds_annexII?.huntingbag_method) &&
    isEmpty(data.data_birds_annexII?.annexII_info);

  return !empty ? (
    <div className="art12-section">
      <h2>10. Information related to Annex II species (Art.7)</h2>
      <table className="art12-table">
        <tbody>
          <tr>
            <th>10.1 Is the Species nationally hunted?</th>
            <td>{viewValue(data.data_birds_annexII?.nationally_hunted)}</td>
          </tr>
          <tr>
            <th>10.2 Hunting bag</th>
            <td>
              <table>
                <tr>
                  <th>a) Unit</th>
                  <td colSpan={6}>
                    {viewValue(data.data_birds_annexII?.huntingbag_unit)}
                  </td>
                </tr>
                <tr>
                  <th>b) Statistics/quantity taken</th>
                  <td>Season/year 1</td>
                  <td>Season/year 2</td>
                  <td>Season/year 3</td>
                  <td>Season/year 4</td>
                  <td>Season/year 5</td>
                  <td>Season/year 6</td>
                </tr>
                <tr>
                  <th>Min. (raw, ie. not rounded)</th>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y1_min)}
                  </td>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y2_min)}
                  </td>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y3_min)}
                  </td>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y4_min)}
                  </td>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y5_min)}
                  </td>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y6_min)}
                  </td>
                </tr>
                <tr>
                  <th>Max. (raw, ie. not rounded)</th>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y1_max)}
                  </td>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y2_max)}
                  </td>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y3_max)}
                  </td>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y4_max)}
                  </td>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y5_max)}
                  </td>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y6_max)}
                  </td>
                </tr>
                <tr>
                  <th>Unknown</th>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y1_unknown)}
                  </td>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y2_unknown)}
                  </td>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y3_unknown)}
                  </td>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y4_unknown)}
                  </td>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y5_unknown)}
                  </td>
                  <td>
                    {viewValue(data.data_birds_annexII?.huntingbag_y6_unknown)}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <th>10.3 Hunting bag Method used</th>
            <td>{viewValue(data.data_birds_annexII?.huntingbag_method)}</td>
          </tr>
          <tr>
            <th>
              10.4 Additional Information{' '}
              <span className="right">(Optional)</span>
            </th>
            <td>{viewValue(data.data_birds_annexII?.annexII_info)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
};

export default Art12_10_InfoAnnexII;
