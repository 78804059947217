/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import moment from 'moment';
import { concat } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Container } from 'design-react-kit/dist/design-react-kit';

import { UniversalLink } from '@plone/volto/components';
import {
  DropdownIndicator,
  selectTheme,
  customSelectStyles,
} from '@plone/volto/components/manage/Widgets/SelectStyling';
import checkSVG from '@plone/volto/icons/check.svg';

import { Icon } from '@italia/components/ItaliaTheme';

import Background from '@package/components/Blocks/SearchCatalog/Background';
import { getCatalogLink } from '@package/components/Views/Catalog/CatalogLink';
import { getSDFLink } from '@package/components/Views/Natura2000/SDF/SDFLink';
import { searchAutocomplete } from '@package/actions';

import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

const messages = defineMessages({
  no_options: {
    id: 'No results',
    defaultMessage: 'Nessun risultato',
  },
  loading: {
    id: 'loading_catalog',
    defaultMessage: 'Sto cercando...',
  },
  natura2000Site: {
    id: 'Natura 2000 Site',
    defaultMessage: 'Sito Natura 2000',
  },
});

const Body = ({ block, reactSelect, reactSelectAsyncPaginate }) => {
  const intl = useIntl();
  moment.locale(intl.locale);
  const history = useHistory();
  const dispatch = useDispatch();
  const AsyncPaginate = reactSelectAsyncPaginate.AsyncPaginate;

  const [selectedOption, setSelectedOption] = useState(null);

  const [options, setOptions] = useState([]);
  const [lastSearch, setLastSearch] = useState('');

  const elabOptions = (result) => {
    let res = [];

    if (result.taxonomy) {
      Object.keys(result.taxonomy).forEach((r) => {
        res = concat(
          res,
          result.taxonomy[r].map((o) => {
            return {
              ...o,
              type: r,
              url: getCatalogLink(o.usageKey, intl.locale),
            };
          }),
        );
      });
    }

    if (result.taxonomy_order?.length > 0) {
      res = res.sort((r1, r2) => {
        let order1 = result.taxonomy_order.indexOf(r1.type);
        let order2 = result.taxonomy_order.indexOf(r2.type);

        if (order1 < order2) {
          return -1;
        } else if (order1 === order2) {
          return r1.name < r2.name ? -1 : 1;
        } else {
          return 1;
        }
      });
    }

    if (result.natura2000?.length > 0) {
      res = concat(
        res,
        result.natura2000.map((o) => {
          return {
            ...o,
            type: intl.formatMessage(messages.natura2000Site),
            url: getSDFLink(o.code),
          };
        }),
      );
    }
    res = res.map((o) => {
      return {
        value: o['@id'],
        label: o.name,
        usageKey: o.usageKey,
        type: o.type,
        url: o.url,
      };
    });

    return res;
  };

  const loadOptions = async (search, previousOptions, additional) => {
    if (search?.length > 0 && lastSearch !== search) {
      setLastSearch(search);
      const result = await dispatch(searchAutocomplete(search));
      const opts = elabOptions(result);

      setOptions(opts);

      return {
        options: opts,
        hasMore: false,
      };
    } else {
      return {
        options: options,
        hasMore: false,
      };
    }
  };

  const onChange = (opt) => {
    setSelectedOption(opt);
  };

  const navigate = () => {
    if (selectedOption?.url) {
      history.push(selectedOption.url);
    }
  };

  useEffect(() => {
    navigate();
  }, [selectedOption]);

  const formatOptionLabel = (o) => {
    const data = o.data || o;
    return (
      <div className="custom-option">
        <div>{data.label}</div>
        <div className="more">
          {data.type === 'canonical' ? 'Canonical name' : data.type}
        </div>
      </div>
    );
  };

  const Option = (props) => {
    const { Option } = reactSelect.components;
    return (
      <Option {...props}>
        <div>{formatOptionLabel(props)}</div>
        {props.isFocused && !props.isSelected && (
          <Icon name={checkSVG} size="24px" color="#b8c6c8" />
        )}
        {props.isSelected && (
          <Icon name={checkSVG} size="24px" color="#007bc1" />
        )}
      </Option>
    );
  };

  return (
    <div className="public-ui searchCatalog">
      <Background {...block} />
      <Container>
        <div className="searchContainer d-flex w-100 align-items-center">
          {block.title && <h2 className="mb-4">{block.title}</h2>}
          <div className="searchbar d-flex w-100">
            <AsyncPaginate
              debounceTimeout={300}
              className="react-select-container inputSearch"
              classNamePrefix="react-select"
              //options={options}
              formatOptionLabel={formatOptionLabel}
              isClearable={true}
              styles={customSelectStyles}
              theme={selectTheme}
              components={{ DropdownIndicator, Option }}
              value={selectedOption}
              defaultOptions={false}
              loadOptions={loadOptions}
              onChange={onChange}
              placeholder={block.placeholder ?? ''}
              noOptionsMessage={() => intl.formatMessage(messages.no_options)}
              loadingMessage={() => intl.formatMessage(messages.loading)}
            />
            <button className="rounded-right" onClick={(e) => navigate()}>
              <Icon icon="it-search" padding={false} size="sm" color="white" />
            </button>
          </div>

          {block.links?.length > 0 && (
            <div className="buttonsContainer mt-2 d-flex">
              {block.links?.map((link, index) => {
                return (
                  <Button
                    color="light"
                    size="sm"
                    key={index}
                    outline
                    tag={UniversalLink}
                    item={link}
                    className="m-2"
                  >
                    {link.title}
                  </Button>
                );
              })}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Body.propTypes = {
  block: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectLazyLibs(['reactSelect', 'reactSelectAsyncPaginate'])(
  Body,
);
