import { GET_WATCHINGS } from '@package/actions';

const initialState = {
  subrequests: {},
};

export const getWatchings = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_WATCHINGS}_PENDING`:
      return {
        ...state,
        subrequests: {
          ...state.subrequests,
          [action.subrequest]: {
            ...(state.subrequests[action.subrequest] || {
              result: null,
            }),
            error: null,
            loaded: false,
            loadingResults: true,
          },
        },
      };

    case `${GET_WATCHINGS}_SUCCESS`:
      return {
        ...state,
        subrequests: {
          ...state.subrequests,
          [action.subrequest]: {
            result: action.result,
            loadingResults: false,
            loaded: true,
          },
        },
      };

    case `${GET_WATCHINGS}_FAIL`:
      return {
        ...state,
        subrequests: {
          ...state.subrequests,
          [action.subrequest]: {
            result: null,
            error: action.error,
            hasError: true,
            loadingResults: false,
            loaded: false,
          },
        },
      };

    default:
      return state;
  }
};
