import '@plone/volto/config';

import { getArt12, getCatalogData, getNatura2000SDF } from '@package/actions';

import CatalogSearchEdit from '@package/components/Blocks/SearchCatalog/Edit';
import CatalogSearchView from '@package/components/Blocks/SearchCatalog/View';
import applyItaliaConfig from '@italia/config/italiaConfig';
/**
 * Add your config changes here.
 * @module config
 */
import searchIcon from 'bootstrap-italia/src/svg/it-search.svg';

export default function applyConfig(voltoConfig) {
  let config = applyItaliaConfig(voltoConfig);

  config.settings = {
    ...config.settings,
    matomoSiteId: 15567,
    matomoUrlBase: 'https://ingestion.webanalytics.italia.it',
    isMultilingual: true,
    supportedLanguages: ['it', 'en'],
    defaultLanguage: 'it',
    externalRoutes: [{ match: '/vegetazioneriparia' }],
    italiaThemeViewsConfig: {
      ...config.settings.italiaThemeViewsConfig,
      imagePosition: 'documentBody', // possible values: afterHeader, documentBody
    },
    siteProperties: {
      /*
        Per tutte le props di siteProperties, può essere definito un oggetto per i siti multilingua, strutturato in questo modo:
        {'default': _valore_della_prop, 'it': _valore_della_prop,...}
        Se l'oggetto non è strutturato in questo modo, viene usato l'oggetto stesso, altrimenti viene preso il valore della corrispondente alla prop della lingua corrente o alla prop 'default' se esiste.
      */
      ...config.settings.siteProperties,

      siteTitle: 'NNB - Ispra',
      siteSubtitle: {
        default: 'Network Nazionale Biodiversità',
        it: 'Network Nazionale Biodiversità',
        en: 'Biodiversity National Network',
      },
      parentSiteTitle: null, //non c'è un sito padre
      parentSiteURL: null,
      subsiteParentSiteTitle: 'NNB - Ispra',
      enableCustomerSatisfaction: false,

      headerslimTertiaryMenu: {
        it: [{ title: 'Scrivici', url: '/it/contatti' }],
        en: [{ title: 'Contact us', url: '/en/contacts' }],
      },
    },

    'volto-gdpr-privacy': {
      ...config.settings['volto-gdpr-privacy'],
      defaultPanelConfig: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
        last_updated: '2023-01-02T17:00:00+00:00',
        text: {
          it: {
            description:
              "Questo sito utilizza i cookie tecnici di navigazione e di sessione per garantire un miglior servizio di navigazione del sito, e cookies analitici per raccogliere informazioni sull'uso del sito da parte degli utenti. Utilizza anche cookies di profilazione dell'utente per fini statistici. Per i cookie di profilazione puoi decidere se abilitarli o meno cliccando sul pulsante 'Impostazioni'. Per saperne di più, su come disabilitare i cookies oppure abilitarne solo alcuni, consulta la nostra <a href='/it/privacy-policy'>Cookie Policy</a>.",
          },
          en: {
            description:
              "This site uses technical navigation and session cookies to ensure a better site navigation service, and analytical cookies to collect information on the use of the site by users. It also uses user profiling cookies for statistical. For profiling cookies you can decide whether to enable them or not by clicking on the 'Settings' button. To find out more, on how to disable cookies or enable only some of them, consult our <a href='/it/privacy-policy'>Cookie Policy</a>.",
          },
        },

        technical: {
          ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical,
          choices: [
            {
              config_key: 'MATOMO',
              text: {
                it: {
                  title: 'Matomo',
                  description:
                    "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
                },
                en: {
                  title: 'Matomo',
                  description:
                    'Matomo cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
                },
                fr: {
                  title: 'Matomo',
                  description:
                    "Les cookie de Matomo sont utilisés pour analyser la navigation sur le site afin de l'améliorer et offrir à l'utilisateur la meilleure expérience de navigation possible.",
                },
              },
            },
            /*...config.settings[
              'volto-gdpr-privacy'
            ].defaultPanelConfig.technical.choices.filter(
              (f) => f.config_key !== 'GANALYTICS',
            ),*/
          ],
        },
      },
    },

    // console.log(`getCatalogData async ${JSON.stringify(location)} ${JSON.stringify(match || {})}`);
    // XXX: in SSR match risulta vuoto e di conseguenza match?.params?.[0] è undefined e poi le
    // chiamate si rompono. aggiunta la condizione sul paramero probabilmente le pagine non andranno
    // comunque con SSR ma lato client le cose funzionano
    asyncPropsExtenders: [
      ...(config.settings.asyncPropsExtenders ?? []),
      {
        path: '*/data/*',
        extend: (dispatchActions) => {
          if (
            dispatchActions.filter(
              (asyncAction) => asyncAction.key === 'getCatalogData',
            ).length === 0
          ) {
            dispatchActions.push({
              key: 'getCatalogData',
              promise: ({ location, store: { dispatch }, match }) =>
                __SERVER__ &&
                match?.params?.[0] &&
                dispatch(getCatalogData(match?.params?.[0])),
            });
          }
          return dispatchActions;
        },
      },
      {
        path: '/Natura2000/SDF/*',
        extend: (dispatchActions) => {
          if (
            dispatchActions.filter(
              (asyncAction) => asyncAction.key === 'getNatura2000SDF',
            ).length === 0
          ) {
            dispatchActions.push({
              key: 'getNatura2000SDF',
              promise: ({ location, store: { dispatch }, match }) =>
                __SERVER__ &&
                match?.params?.[0] &&
                dispatch(getNatura2000SDF(match?.params?.[0])),
            });
          }
          return dispatchActions;
        },
      },
      {
        path: '/Art12/View/*',
        extend: (dispatchActions) => {
          if (
            dispatchActions.filter(
              (asyncAction) => asyncAction.key === 'getArt12',
            ).length === 0
          ) {
            dispatchActions.push({
              key: 'getArt12',
              promise: ({ location, store: { dispatch }, match }) =>
                __SERVER__ &&
                match?.params?.[0] &&
                match?.params?.[1] &&
                dispatch(getArt12(match?.params?.[0], match?.params?.[1])),
            });
          }
          return dispatchActions;
        },
      },
    ],
  };

  // config.views = {
  //   ...config.views,
  // };
  // config.widgets = {
  //   ...config.widgets,
  // };

  config.blocks = {
    ...config.blocks,
    blocksConfig: {
      ...config.blocks.blocksConfig,
      listing: {
        ...config.blocks.blocksConfig.listing,
        listing_bg_colors: [
          { name: 'default', label: 'default' },
          { name: 'light-green', label: 'Verde chiaro' },
        ], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
        listing_items_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
      },

      searchCatalog: {
        id: 'searchCatalog',
        title: 'Ricerca nel catalogo animali',
        icon: searchIcon,
        group: 'search',
        view: CatalogSearchView,
        edit: CatalogSearchEdit,
        restricted: false,
        mostUsed: false,
        security: {
          addPermission: [],
          view: [],
        },
        sidebarTab: 1,
      },
    },
  };

  return config;
}
