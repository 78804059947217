import { GET_POLLNET_DATA } from '@package/actions';

const initialState = {
  // error: null,
  // hasError: false,
  // result: [],
  // loadingResults: false,
  // loaded: false,
  subrequests: {},
};

export const getPollNetDataReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_POLLNET_DATA}_PENDING`:
      return {
        ...state,
        subrequests: {
          ...state.subrequests,
          [action.subrequest]: {
            loadingResults: true,
            loaded: false,
          },
        },
      };

    case `${GET_POLLNET_DATA}_SUCCESS`:
      return {
        ...state,
        subrequests: {
          ...state.subrequests,
          [action.subrequest]: {
            result: action.result,
            loadingResults: false,
            loaded: true,
          },
        },
      };

    case `${GET_POLLNET_DATA}_FAIL`:
      return {
        ...state,
        subrequests: {
          ...state.subrequests,
          [action.subrequest]: {
            error: action.error,
            hasError: true,
            loadingResults: false,
            loaded: false,
          },
        },
      };

    default:
      return state;
  }
};
