/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Spinner } from 'design-react-kit/dist/design-react-kit';

import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import { Icon, Pagination } from '@italia/components/ItaliaTheme';
import { useDebouncedEffect } from '@italia/helpers';

import { getSitiNatura2000 } from '@package/actions';
import { getSDFLink } from '@package/components/Views/Natura2000/SDF/SDFLink';

const messages = defineMessages({
  Natura2000Sites: {
    id: 'Natura2000_title',
    defaultMessage: 'Siti Natura 2000',
  },
  viewNatura2000Sites: {
    id: 'Natura2000_viewSites',
    defaultMessage: 'Vedi i siti Natura 2000',
  },
  sitesFound: {
    id: 'Natura2000_sitesFounds',
    defaultMessage: 'siti Natura 2000 trovati',
  },
  searchSitiNatura2000: {
    id: 'Natura2000_search',
    defaultMessage: 'cerca...',
  },
  loading: {
    id: 'Natura2000_loading',
    defaultMessage: 'Sto caricando i siti Natura 2000...',
  },
  max_items_info: {
    id: 'Natura2000_max_items_info',
    defaultMessage: 'Il numero massimo di elementi è limitato a 10.000 record.',
  },
});

const Natura2000Sites = ({ source }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [viewSites, setViewSites] = useState(false);
  const [filter, setFilter] = useState('');
  const b_size = 25;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const sitesURL = flattenToAppURL(source);
  const sites = useSelector((state) => {
    return state.sitiNatura2000?.subrequests?.[sitesURL];
  });

  useEffect(() => {
    setCurrentPage(1);
    setTotalPages(1);
    setFilter('');
    setViewSites(false);
  }, []);

  const toggleSites = () => {
    if (!sites?.loaded) {
      setCurrentPage(1);
      doRequest();
      setViewSites(true);
    } else {
      setViewSites(!viewSites);
    }
  };

  useEffect(() => {
    if (sites?.result?.items_total != null) {
      setTotalPages(Math.ceil(sites.result.items_total / b_size));
    }
  }, [sites]);

  const handlePaginationChange = (e, { activePage }) => {
    setCurrentPage(activePage.children);
    doRequest(filter, activePage.children);
  };

  const doRequest = (search, page) => {
    dispatch(
      getSitiNatura2000(sitesURL, search, page ? (page - 1) * b_size : 0),
    );
  };

  useDebouncedEffect(
    () => {
      if (sites?.loaded) {
        setCurrentPage(1);
        return doRequest(filter);
      }
      return null;
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    1000,
    [filter],
  );

  return source ? (
    <div className="sites-list mt-3 mb-5">
      <h3> {intl.formatMessage(messages.Natura2000Sites)}</h3>
      {!sites?.loaded && (
        <Button
          color="primary"
          size="xs"
          onClick={() => toggleSites()}
          className="m-2"
        >
          {intl.formatMessage(messages.viewNatura2000Sites)}
        </Button>
      )}
      {sites?.loadingResults && (
        <div className="mt-5 mb-5 loading">
          <Spinner active double={false} small={false} tag="span" />
          <div>{intl.formatMessage(messages.loading)}</div>
        </div>
      )}
      {sites?.loaded && (
        <>
          <div className="results-header">
            <div className="results-info">
              {intl.formatMessage(messages.max_items_info)}
              <br />
              {sites?.result?.items_total}{' '}
              {intl.formatMessage(messages.sitesFound)}
            </div>
            <div>
              <input
                type="text"
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                placeholder={intl.formatMessage(messages.searchSitiNatura2000)}
              />
            </div>
          </div>
          <div className="results-list">
            {sites?.result?.items.map((r) => (
              <div className="results-list-item" key={r.code}>
                <div className="item-name">
                  {r.code}
                  {r.code && r.label ? ' - ' : ''}
                  {r.label}
                </div>
                <div className="item-link">
                  <Button
                    tag={UniversalLink}
                    href={r.geoviewer_url}
                    className="my-2"
                    outline
                    color="primary"
                    icon
                    size="xs"
                  >
                    <Icon icon="it-external-link" padding={false} />
                    GEOVIEWER
                  </Button>
                </div>
                <div className="item-link">
                  <Button
                    tag={UniversalLink}
                    href={getSDFLink(r.code, intl.locale)}
                    className="my-2"
                    outline
                    color="primary"
                    icon
                    size="xs"
                  >
                    <Icon icon="it-note" padding={false} />
                    SDF
                  </Button>
                </div>
              </div>
            ))}
          </div>

          {totalPages > 1 && (
            <div className="pagination-wrapper">
              <Pagination
                activePage={currentPage}
                totalPages={totalPages}
                onPageChange={(e, { activePage }) => {
                  handlePaginationChange(e, { activePage });
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  ) : null;
};

export default Natura2000Sites;
