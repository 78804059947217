/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import logoNatura2000 from '~/components/Layout/images/natura2000_logoMedium.jpeg';

const SDFHeader = ({ data }) => {
  return (
    <div className="sdf-header" id="top">
      <div className="logo">
        <img src={logoNatura2000} alt="Natura2000" />
      </div>
      <div className="title">
        <h1>NATURA 2000 - STANDARD DATA FORM </h1>
        <div>
          For Special Protection Areas (SPA),
          <br />
          Proposed Sites for Community Importance (pSCI), Sites of Community
          <br />
          Importance (SCI) and for Special Areas of Conservation (SAC)
        </div>
      </div>
    </div>
  );
};

export default SDFHeader;
