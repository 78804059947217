/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDF2_6 = ({ data }) => {
  return (
    <>
      <h2>2.6 Biogeographical Region(s)</h2>
      <table className="SDFtableNoBorder MinimalHeight">
        <tbody>
          <tr>
            <td width="22%">{data.biogeo_id}</td>
            <td align="left">({Number(data.biogeo_percent).toFixed(2)} %)</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SDF2_6;
