/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { LinkList, LinkListItem } from 'design-react-kit/dist/design-react-kit';
import { Icon } from '@italia/components/ItaliaTheme';
import { CatalogLink } from '@package/components/Views/Catalog/CatalogLink';

const ChildrenItems = ({ items }) => {
  return items?.length > 0 ? (
    <div className="children mt-3 mb-5">
      <LinkList tag="div">
        {items
          .filter((i) => i.name?.length > 0 && i.name !== '0')
          .map((item) => (
            <LinkListItem
              className="left-icon"
              tag={CatalogLink}
              usageKey={item.usageKey}
              key={item.usageKey}
            >
              <Icon
                className="left"
                color="primary"
                icon="it-chevron-right"
                padding={false}
                style={{
                  ariaHidden: true,
                }}
              />
              <span>{item.name}</span>
            </LinkListItem>
          ))}
      </LinkList>
    </div>
  ) : null;
};

export default ChildrenItems;
