import React from 'react';
import { useIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';

const getSDFLink = (code, locale) => {
  return `${locale ? '/' + locale : ''}/Natura2000/SDF/${code}`;
};

const SDFLink = (props) => {
  const intl = useIntl();
  let p = { ...props };
  delete p.code;

  return (
    props.code && (
      <UniversalLink {...p} href={getSDFLink(props.code, intl.locale)} />
    )
  );
};

export { SDFLink, getSDFLink };
