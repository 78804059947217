export const GET_CATALOG_DATA = 'GET_CATALOG_DATA';

export function getCatalogData(usageKey) {
  return {
    type: GET_CATALOG_DATA,
    request: {
      op: 'get',
      path: `/@data/` + usageKey,
    },
  };
}
