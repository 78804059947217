export const GET_SITI_NATURA_2000 = 'GET_SITI_NATURA_2000';

export function getSitiNatura2000(url, searchableText, b_start) {
  return {
    type: GET_SITI_NATURA_2000,
    subrequest: url,
    request: {
      op: 'get',
      path: `${url}`,
      params: { searchableText, b_start },
    },
  };
}
