/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

const SDF1_5 = ({ data }) => {
  const intl = useIntl();
  moment.locale(intl.locale);
  return (
    <>
      <h2>1.5 Update date</h2>
      <table className="SDFtable MinimalHeight">
        <tbody>
          <tr>
            <td>{moment(data.site_update_date).format('YYYY-MM')}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SDF1_5;
