/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDF4_5 = ({ data }) => {
  return (
    <div className="clear">
      <h2>4.5 Documentation</h2>
      <table className="SDFtable MinimalHeight">
        <tbody>
          <tr>
            <td>{data.doc_description}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SDF4_5;
