/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDFPrint = ({ data }) => {
  const print = () => {
    window.print();
  };
  return (
    <div className="text-center">
      <input
        type="button"
        className="btn btn-primary btn-xs"
        name="print"
        value="Print Standard Data Form"
        onClick={() => print()}
      />
    </div>
  );
};

export default SDFPrint;
