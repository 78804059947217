export const GET_CITIZEN_SCIENCE_DATA = 'GET_CITIZEN_SCIENCE_DATA';

export function getCitizenScienceData(url) {
  return {
    type: GET_CITIZEN_SCIENCE_DATA,
    request: {
      op: 'get',
      path: `${url}`,
    },
  };
}
