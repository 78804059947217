/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDF1_6 = ({ data }) => {
  return (
    <>
      <h2>1.6 Respondent:</h2>
      <table className="SDFtable MinimalHeight">
        <tbody>
          <tr>
            <td className="Bold" width="22%">
              Name/Organisation:
            </td>
            <td>{data.resp_name}</td>
          </tr>
          <tr>
            <td className="Bold" width="22%">
              Address:
            </td>
            <td>{data.resp_address}</td>
          </tr>
          <tr>
            <td className="Bold" width="22%">
              Email:
            </td>
            <td>{data.resp_email}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SDF1_6;
