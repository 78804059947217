import React from 'react';
import { viewValue, isEmpty, getArrayItems } from '../utils';

const Art12_7_PressuresAndThreats = ({ data }) => {
  const empty =
    data.pressures?.length === 0 &&
    data.threats?.length === 0 &&
    isEmpty(data.pressures_threats_info?.pressures_source) &&
    isEmpty(data.pressures_threats_info?.pressures_info);

  return !empty ? (
    <div className="art12-section">
      <h2>7. Main pressures and threats</h2>
      <table className="art12-table">
        <tbody>
          {(data.pressures?.length > 0 || data.threats?.length > 0) && (
            <tr>
              <th colSpan={3}>7.1 Characterisation of pressures/threats</th>
            </tr>
          )}

          {data.pressures?.length > 0 && (
            <>
              <tr>
                <th>a) Pressure</th>
                <th>b) Ranking</th>
                <th>c) Location</th>
              </tr>
              {getArrayItems(data.pressures)?.map((pressure) => (
                <tr>
                  <td>{viewValue(pressure.code)}</td>
                  <td>{viewValue(pressure.ranking)}</td>
                  <td>{viewValue(pressure.location)}</td>
                </tr>
              ))}
            </>
          )}
          {data.threats?.length > 0 && (
            <>
              <tr>
                <th>a) Threats</th>
                <th>b) Ranking</th>
                <th>c) Location</th>
              </tr>
              {getArrayItems(data.threats)?.map((threats) => (
                <tr>
                  <td>{viewValue(threats.code)}</td>
                  <td>{viewValue(threats.ranking)}</td>
                  <td>{viewValue(threats.location)}</td>
                </tr>
              ))}
            </>
          )}
          <tr>
            <th>
              7.2 Sources of information{' '}
              <span className="right">(Optional)</span>
            </th>
            <td colSpan={2}>
              {viewValue(data.pressures_threats_info?.pressures_source)}
            </td>
          </tr>
          <tr>
            <th>7.3 Additional information</th>
            <td colSpan={2}>
              {viewValue(data.pressures_threats_info?.pressures_info)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
};

export default Art12_7_PressuresAndThreats;
