/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { UniversalLink } from '@plone/volto/components';

const SDF3_3 = ({ data }) => {
  return (
    <>
      <h2>3.3 Other important species of flora and fauna (optional)</h2>
      <table className="SDFtable3rd no-border-collapse">
        <thead>
          <tr>
            <th colspan="5" className="MinimalHeight DivisorRight">
              <h3>Species</h3>
            </th>
            <th colspan="4" className="DivisorRight">
              <h3>Population in the site</h3>
            </th>
            <th colspan="6">
              <h3>Motivation</h3>
            </th>
          </tr>
          <tr>
            <th className="DivisorTop DivisorRight">Group</th>
            <th className="DivisorTop DivisorRight">CODE</th>
            <th className="DivisorTop DivisorRight">Scientific Name</th>
            <th className="DivisorTop DivisorRight">S</th>
            <th className="DivisorTop DivisorRight">NP</th>
            <th className="DivisorTop DivisorRight" colspan="2">
              Size
            </th>
            <th className="DivisorTop DivisorRight">Unit</th>
            <th className="DivisorTop DivisorRight">Cat.</th>
            <th className="DivisorTop DivisorRight" colspan="2">
              Species Annex
            </th>
            <th className="DivisorTop DivisorRight" colspan="4">
              Other categories
            </th>
          </tr>
          <tr>
            <th className="DivisorTop DivisorRight" width="5%"></th>
            <th className="DivisorTop DivisorRight" width="5%"></th>
            <th className="DivisorTop DivisorRight" width="15%"></th>
            <th className="DivisorTop DivisorRight" width="5%"></th>
            <th className="DivisorTop DivisorRight" width="5%"></th>
            <th className="DivisorTop DivisorRight" width="10%">
              Min
            </th>
            <th className="DivisorTop DivisorRight" width="10%">
              Max
            </th>
            <th className="DivisorTop DivisorRight" width="5%"></th>
            <th className="DivisorTop DivisorRight" width="10%">
              C|R|V|P
            </th>
            <th className="DivisorTop DivisorRight" width="5%">
              IV
            </th>
            <th className="DivisorTop DivisorRight" width="5%">
              V
            </th>
            <th className="DivisorTop DivisorRight" width="5%">
              A
            </th>
            <th className="DivisorTop DivisorRight" width="5%">
              B
            </th>
            <th className="DivisorTop DivisorRight" width="5%">
              C
            </th>
            <th className="DivisorTop DivisorRight" width="5%">
              D
            </th>
          </tr>
        </thead>
        <tbody>
          {data.other_species?.map((os, i) => (
            <tr key={'otherspecies' + i}>
              <td className="DivisorTop DivisorRight">
                {os.other_species_group}
              </td>
              <td className="DivisorTop DivisorRight">
                <UniversalLink href={os.code_url}>
                  {os.other_species_code}
                </UniversalLink>
              </td>
              <td className="DivisorTop DivisorRight">
                <UniversalLink href={os.name_url}>
                  {os.other_species_name}
                </UniversalLink>
              </td>
              <td className="DivisorTop DivisorRight">
                {os.other_species_sensitive}
              </td>
              <td className="DivisorTop DivisorRight">{os.other_species_np}</td>
              <td className="DivisorTop DivisorRight">
                {os.other_species_size_min}
              </td>
              <td className="DivisorTop DivisorRight">
                {os.other_species_size_max}
              </td>
              <td className="DivisorTop DivisorRight">
                {os.other_species_unit}
              </td>
              <td className="DivisorTop DivisorRight">
                {os.other_species_category}
              </td>
              <td className="DivisorTop DivisorRight">
                {os.other_species_motivation === 'IV' ? 'X' : ''}
              </td>
              <td className="DivisorTop DivisorRight">
                {os.other_species_motivation === 'V' ? 'X' : ''}
              </td>
              <td className="DivisorTop DivisorRight">
                {os.other_species_motivation === 'A' ? 'X' : ''}
              </td>
              <td className="DivisorTop DivisorRight">
                {os.other_species_motivation === 'B' ? 'X' : ''}
              </td>
              <td className="DivisorTop DivisorRight">
                {os.other_species_motivation === 'C' ? 'X' : ''}
              </td>
              <td className="DivisorTop">
                {os.other_species_motivation === 'D' ? 'X' : ''}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ul className="legend">
        <li>
          <b>Group:</b> A = Amphibians, B = Birds, F = Fish, Fu = Fungi, I =
          Invertebrates, L = Lichens, M = Mammals, P = Plants, R = Reptiles
        </li>
        <li>
          <b>CODE:</b> for Birds, Annex IV and V species the code as provided in
          the reference portal should be used in addition to the scientific name
        </li>
        <li>
          <b>S:</b> in case that the data on species are sensitive and therefore
          have to be blocked for any public access enter: yes
        </li>
        <li>
          <b>NP:</b> in case that a species is no longer present in the site
          enter: x (optional)
        </li>
        <li>
          <b>Unit:</b> i = individuals, p = pairs or other units according to
          the standard list of population units and codes in accordance with
          Article 12 and 17 reporting, (see{' '}
          <a href="https://bd.eionet.europa.eu/activities/Natura_2000/reference_portal">
            reference portal
          </a>
          )
        </li>
        <li>
          <b>Cat.:</b> Abundance categories: C = common, R = rare, V = very
          rare, P = present
        </li>
        <li>
          <b>Motivation categories: </b>
          <b>IV, V:</b> Annex Species (Habitats Directive), <b>A:</b> National
          Red List data; <b>B:</b> Endemics; <b>C:</b> International
          Conventions; <b>D:</b> other reasons
        </li>
      </ul>
    </>
  );
};

export default SDF3_3;
