/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDF1_3 = ({ data }) => {
  return (
    <>
      <h2>1.3 Site name</h2>
      <table className="SDFtable MinimalHeight">
        <tbody>
          <tr>
            <td>{data.site_name}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SDF1_3;
