/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import moment from 'moment';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Spinner,
  Button,
} from 'design-react-kit/dist/design-react-kit';
import { Helmet } from '@plone/volto/helpers';

import { getArt12 } from '@package/actions';

import ViewError from '@package/components/Views/Catalog/ViewError';
import Art12_1_SpeciesInformation from './parts/Art12_1_SpeciesInformation';
import Art12_2_PopulationSize from './parts/Art12_2_PopulationSize';
import Art12_3_PopulationTrend from './parts/Art12_3_PopulationTrend';
import Art12_4_BreedingDistribution from './parts/Art12_4_BreedingDistribution';
import Art12_5_BreedingDistributionTrend from './parts/Art12_5_BreedingDistributionTrend';
import Art12_6_ProgressInWork from './parts/Art12_6_ProgressInWork';
import Art12_7_PressuresAndThreats from './parts/Art12_7_PressuresAndThreats';
import Art12_8_ConservationMeasures from './parts/Art12_8_ConservationMeasures';
import Art12_9_Natura2000SpasCoverage from './parts/Art12_9_Natura2000SpasCoverage';
import Art12_10_InfoAnnexII from './parts/Art12_10_InfoAnnexII';

const messages = defineMessages({
  loading: {
    id: 'Catalog_loading',
    defaultMessage: 'Sto caricando i dati...',
  },
  goBack: {
    id: 'Art12_goBack',
    defaultMessage: 'Torna alla pagina precedente',
  },
  art12: {
    id: 'Art12_title',
    defaultMessage: 'Art. 12 Direttiva uccelli',
  },
});

const Art12View = (props) => {
  const intl = useIntl();
  moment.locale(intl.locale);
  const dispatch = useDispatch();
  const history = useHistory();
  const name = props?.match?.params?.[1];
  const season = props?.match?.params?.[2];

  const art12 = useSelector((state) => {
    return state.art12;
  });

  const data = useSelector((state) => {
    return state.art12?.result;
  });

  useEffect(() => {
    if (!art12.loadingResults) {
      dispatch(getArt12(name, season));
    }
  }, [name, season]);

  return (
    <div id="page-view-art12" className="ui container">
      {art12.hasError ? (
        <ViewError error={art12.error} />
      ) : (
        <>
          <Helmet title={data.canonicalName} />
          {art12.loadingResults ? (
            <Container>
              <div className="mt-5 mb-5 loading">
                <Spinner active double={false} small={false} tag="span" />
                <div>{intl.formatMessage(messages.loading)}</div>
              </div>
            </Container>
          ) : (
            <>
              <div className="full-width PageHeaderWrapper pt-5 pb-2 mb-5">
                <Container className="px-3 px-md-4 mb-4">
                  <div className="title-description-wrapper">
                    <h1>{intl.formatMessage(messages.art12)}</h1>
                  </div>
                </Container>
              </div>

              <Container className="data-content">
                {/* <h1>{intl.formatMessage(messages.art12)}</h1> */}

                <Art12_1_SpeciesInformation data={data} />
                <Art12_2_PopulationSize data={data} />
                <Art12_3_PopulationTrend data={data} />
                <Art12_4_BreedingDistribution data={data} />
                <Art12_5_BreedingDistributionTrend data={data} />
                <Art12_6_ProgressInWork data={data} />
                <Art12_7_PressuresAndThreats data={data} />
                <Art12_8_ConservationMeasures data={data} />
                <Art12_9_Natura2000SpasCoverage data={data} />
                <Art12_10_InfoAnnexII data={data} />

                <div className="back-button">
                  <Button
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    {intl.formatMessage(messages.goBack)}
                  </Button>
                </div>
              </Container>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Art12View;
