/**
 * HeaderCenter component.
 * @module components/Header/HeaderCenter
 */

/* CUSTOMIZATIONS:
    - removed siteTitle
    - moved HeaderSearch to HeaderSlimRightZone component
    - added 'loghi' on right zone
*/

import React from 'react';
import { UniversalLink } from '@plone/volto/components';
import { useSelector } from 'react-redux';
import {
  Header,
  HeaderContent,
  HeaderRightZone,
} from 'design-react-kit/dist/design-react-kit';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  Logo,
  SocialHeader,
  //HeaderSearch,
} from '@italia/components/ItaliaTheme';

//eslint-disable-next-line import/no-unresolved
import logoIspra from '~/components/Layout/images/logo_right.png?width=284';
//eslint-disable-next-line import/no-unresolved
import logoMinistero from '~/components/Layout/images/ministero.png?width=92';

const HeaderCenter = () => {
  const subsite = useSelector((state) => state.subsite?.data);

  let logoSubsite = subsite?.subsite_logo && (
    <figure className="icon">
      <img
        src={flattenToAppURL(subsite.subsite_logo.scales?.mini?.download)}
        alt="Logo"
      />
    </figure>
  );

  return (
    <Header small={false} theme="" type="center">
      <HeaderContent>
        <div className="it-brand-wrapper">
          <UniversalLink
            href={subsite?.['@id'] ? flattenToAppURL(subsite['@id']) : '/'}
          >
            {subsite?.subsite_logo ? logoSubsite : <Logo />}
            {/* <div className="it-brand-text">
              <h2 className="no_toc">
                {subsite?.title || getSiteProperty('siteTitle', intl.locale)}
              </h2>
              <h3 className="no_toc d-none d-md-block">
                {subsite?.description ||
                  getSiteProperty('siteSubtitle', intl.locale)}
              </h3>
            </div> */}
          </UniversalLink>
        </div>
        <HeaderRightZone>
          <div className="loghi d-none d-md-block">
            <UniversalLink
              href={'https://www.isprambiente.gov.it/'}
              title="Sistema Nazionale per la Protezione dell'Ambiente"
            >
              <img
                src={logoIspra}
                width="284"
                height="70"
                alt="Logo Sistema Nazionale per la Protezione dell'Ambiente"
                className="icon"
              />
            </UniversalLink>
            <UniversalLink
              href={'https://www.mite.gov.it'}
              title="Ministero per la transizione ecologica"
            >
              <img
                src={logoMinistero}
                width="92"
                height="70"
                alt="Logo Ministero per la transizione ecologica"
                className="icon"
              />
            </UniversalLink>
          </div>

          <SocialHeader />
          {/* <HeaderSearch /> */}
        </HeaderRightZone>
      </HeaderContent>
    </Header>
  );
};

export default HeaderCenter;
