import { GET_CATALOG_DATA } from '@package/actions';

const initialState = {
  error: null,
  hasError: false,
  result: [],
  loadingResults: false,
  loaded: false,
};

export const getCatalogDataReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_CATALOG_DATA}_PENDING`:
      return {
        ...state,
        loadingResults: true,
        loaded: false,
      };

    case `${GET_CATALOG_DATA}_SUCCESS`:
      return {
        ...state,
        result: action.result,
        loadingResults: false,
        loaded: true,
      };

    case `${GET_CATALOG_DATA}_FAIL`:
      return {
        ...state,
        error: action.error,
        hasError: true,
        loadingResults: false,
        loaded: false,
        result: [],
      };

    default:
      return state;
  }
};
