import React from 'react';
import { viewValue, isEmpty } from '../utils';

const Art12_3_PopulationTrend = ({ data }) => {
  const empty =
    isEmpty(data.population_trend_period) &&
    isEmpty(data.population_trend) &&
    isEmpty(data.population_trend_magnitude_min) &&
    isEmpty(data.population_trend_magnitude_max) &&
    isEmpty(data.population_trend_magnitude) &&
    isEmpty(data.population_trend_method) &&
    isEmpty(data.population_trend_sources) &&
    isEmpty(data.population_sources) &&
    isEmpty(data.population_trend_long_period) &&
    isEmpty(data.population_trend_long) &&
    isEmpty(data.population_trend_long_magnitude_min) &&
    isEmpty(data.population_trend_long_magnitude_max) &&
    isEmpty(data.population_trend_long_magnitude) &&
    isEmpty(data.population_trend_long_method) &&
    isEmpty(data.population_trend_long_sources) &&
    isEmpty(data.population_trend_info);
  return !empty ? (
    <div className="art12-section">
      <h2>3. Population trend</h2>
      <table className="art12-table">
        <tbody>
          <tr>
            <th colSpan={2} className="header-3">
              3.1 Short-term trend (last 12 years)
            </th>
          </tr>
          <tr>
            <th>3.1.1 Short-term trend Period</th>
            <td>{viewValue(data.population_trend_period)}</td>
          </tr>
          <tr>
            <th>3.1.2 Short-term trend Direction</th>
            <td>{viewValue(data.population_trend)}</td>
          </tr>
          <tr>
            <th>3.1.3a Short-term trend Magnitude - Minimum</th>
            <td>{viewValue(data.population_trend_magnitude_min)}</td>
          </tr>
          <tr>
            <th>3.1.3b Short-term trend Magnitude - Maximum</th>
            <td>{viewValue(data.population_trend_magnitude_max)}</td>
          </tr>
          <tr>
            <th>3.1.3c Short-term trend Magnitude - Best single value</th>
            <td>{viewValue(data.population_trend_magnitude)}</td>
          </tr>
          <tr>
            <th>3.1.4 Short term trend method used</th>
            <td>{viewValue(data.population_trend_method)}</td>
          </tr>
          <tr>
            <th>3.1.5 Sources</th>
            <td>{viewValue(data.population_trend_sources)}</td>
          </tr>
          <tr>
            <th className="header-3" colSpan={2}>
              3.2 Long-term trend (since c. 1980)
            </th>
          </tr>
          <tr>
            <th>3.2.1 Long-term trend Period</th>
            <td>{viewValue(data.population_trend_long_period)}</td>
          </tr>
          <tr>
            <th>3.2.2 Long-term trend Direction</th>
            <td>{viewValue(data.population_trend_long)}</td>
          </tr>
          <tr>
            <th>3.2.3a Long-term trend Magnitude - Minimum</th>
            <td>{viewValue(data.population_trend_long_magnitude_min)}</td>
          </tr>
          <tr>
            <th>3.2.3b Long-term trend Magnitude - Maximum</th>
            <td>{viewValue(data.population_trend_long_magnitude_max)}</td>
          </tr>
          <tr>
            <th>3.2.3c Long-term trend Magnitude - Best single value</th>
            <td>{viewValue(data.population_trend_long_magnitude)}</td>
          </tr>
          <tr>
            <th>3.2.4 Long-term trend Method used</th>
            <td>{viewValue(data.population_trend_long_method)}</td>
          </tr>

          <tr>
            <th>3.2.5 Sources</th>
            <td>{viewValue(data.population_trend_long_sources)}</td>
          </tr>
          <tr>
            <th>
              3.3. Additional information{' '}
              <span className="right">(Optional)</span>
            </th>
            <td>{viewValue(data.population_trend_info)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
};

export default Art12_3_PopulationTrend;
