import React from 'react';
import { viewValue, isEmpty, getObjectProps } from '../utils';

const Art12_8_ConservationMeasures = ({ data }) => {
  const conservation_measures = getObjectProps(data.conservation_measures);

  let measures = [];
  if (conservation_measures?.undefined) {
    Object.keys(conservation_measures?.undefined).forEach((k) => {
      measures.push(conservation_measures.undefined[k][0]);
    });
  }

  const empty =
    isEmpty(conservation_measures?.measures_needed) &&
    isEmpty(conservation_measures?.measures_status) &&
    isEmpty(conservation_measures?.measures_purpose) &&
    isEmpty(conservation_measures?.measures_location) &&
    isEmpty(conservation_measures?.measures_response) &&
    measures?.length === 0 &&
    isEmpty(conservation_measures?.measures_info);

  return !empty ? (
    <div className="art12-section">
      <h2>8. Conservation measures</h2>
      <table className="art12-table">
        <tbody>
          <tr>
            <th>8.1a Status of measures - Are measures needed?</th>
            <td>{viewValue(conservation_measures?.measures_needed)}</td>
          </tr>
          <tr>
            <th>8.1b Status of measures - Indicate the status of measures </th>
            <td>{viewValue(conservation_measures?.measures_status)}</td>
          </tr>
          <tr>
            <th>8.2 Main purpose of the measures taken </th>
            <td>{viewValue(conservation_measures?.measures_purpose)}</td>
          </tr>
          <tr>
            <th>8.3 Location of the measures </th>
            <td>{viewValue(conservation_measures?.measures_location)}</td>
          </tr>
          <tr>
            <th>8.4 Response to the measures </th>
            <td>{viewValue(conservation_measures?.measures_response)}</td>
          </tr>
          <tr>
            <th>8.5 List of main conservation measures </th>
            <td>
              {measures.length > 0 &&
                measures.map((m) => {
                  return <div>{viewValue(m)}</div>;
                })}
            </td>
          </tr>
          <tr>
            <th>8.6 Additional information </th>
            <td>{viewValue(conservation_measures?.measures_info)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
};

export default Art12_8_ConservationMeasures;
