/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import DataTable from 'react-data-table-component';
import {
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from 'design-react-kit/dist/design-react-kit';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';

import { Icon } from '@italia/components/ItaliaTheme';
import { getWatchings } from '@package/actions';
import ViewError from '@package/components/Views/Catalog/ViewError';
import { downloadFile } from '@package/components/Views/Catalog/Utils/DownloadFile';

const messages = defineMessages({
  Watchings: {
    id: 'Watchings',
    defaultMessage: 'Osservazioni',
  },
  canonicalName: {
    id: 'Watchings_canonicalName',
    defaultMessage: 'Nome scientifico',
  },
  year: {
    id: 'Watchings_year',
    defaultMessage: 'Anno osservazione',
  },
  datasource: {
    id: 'Watchings_datasource',
    defaultMessage: 'Banca dati',
  },
  latitude: {
    id: 'Watchings_latitude',
    defaultMessage: 'Lat',
  },
  longitude: {
    id: 'Watchings_longitude',
    defaultMessage: 'Lon',
  },
  no_data: {
    id: 'Watchings_nodata',
    defaultMessage: 'Nessuna osservazione trovata',
  },
  downloadCSV: {
    id: 'Watchings_downloadCSV',
    defaultMessage: 'Download dei dati visualizzati',
  },
  downloadElastic: {
    id: 'Watchings_downloadElastic',
    defaultMessage: 'Download dei dati completi',
  },
  loading: {
    id: 'Watchings_loading',
    defaultMessage: "Sto caricando l'elenco delle osservazioni...",
  },
  clear_filters: {
    id: 'Watchings_clearFilters',
    defaultMessage: 'Annulla i filtri e mostra tutti i risultati',
  },
  filtra_per: {
    id: 'Watchings_filtraPer',
    defaultMessage: 'Filtra per',
  },
  max_items_info: {
    id: 'Watchings_max_items_info',
    defaultMessage:
      'Il numero di osservazioni è limitato a 2000 record, pertanto le informazioni presenti in questa tabella sono parziali. Cliccando sul pulsante "Download dati completi" è possibile scaricare il CSV di tutti i dati, oppure è possibile vederli tutti su mappa',
  },
  downloadElasticInfos: {
    id: 'Watchings_download_elastic_infos',
    defaultMessage:
      "E' necessario qualche istante per la generazione del file. Se il file non è disponibile riprovare più tardi.",
  },
  geoviewer_link: {
    id: 'Geoviewer link',
    defaultMessage: 'Vedi sulla mappa',
  },
  modalElasticDownloadHeader: {
    id: 'Elastic download: modal header',
    defaultMessage: 'Il dowload è in preparazione',
  },
  modalElasticDownloadBody: {
    id: 'Elastic download: modal body',
    defaultMessage:
      'A questo link {link} sarà possibile scaricare il file una volta pronto.',
  },
  close: {
    id: 'Close',
    defaultMessage: 'Chiudi',
  },
});

const Watchings = ({ data }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const watchingsUrl = data?.['@components']?.watchings;
  const requestURL = flattenToAppURL(watchingsUrl);
  const DEFAULT_SORT_ON = 'year';
  const DEFAULT_SORT_ORDER = 'descending';
  const [downloadCSVStatus, setDownloadCSVStatus] = useState({});
  //const [downloadElasticStatus, setDownloadElasticStatus] = useState({});
  const [modalElasticOpen, setModalElasticOpen] = useState(false);
  const [filters, setFilters] = useState({});

  const watchings = useSelector((state) => {
    return state.watchings?.subrequests?.[requestURL] ?? {};
  });

  const doRequest = () => {
    dispatch(getWatchings(requestURL));
  };

  useEffect(() => {
    if (watchingsUrl && !watchings.loading && !watchings.loaded) {
      doRequest();
    }
  }, [watchingsUrl]);

  const onChangeFilter = (e, column) => {
    setFilters({ ...filters, [column.id]: e.target.value });
  };

  const drawCell = (row, index, column, id) => {
    if (column.selector === 'datasource') {
      return <div dangerouslySetInnerHTML={{ __html: row[column.selector] }} />;
    }
    return <>{row[column.selector]}</>;
  };

  const columns = [
    {
      name: intl.formatMessage(messages.canonicalName),
      selector: 'canonicalname',
      sortable: true,
      //width: '200px',
    },
    {
      name: intl.formatMessage(messages.year),
      selector: 'year',
      sortable: true,
      width: '100px',
    },
    {
      name: intl.formatMessage(messages.datasource),
      selector: 'datasource',
      sortable: true,
      //  width: '150px',
      cell: drawCell,
    },
    {
      name: intl.formatMessage(messages.latitude),
      selector: 'lat',
      sortable: false,
      width: '150px',
    },
    {
      name: intl.formatMessage(messages.longitude),
      selector: 'lon',
      sortable: false,
      width: '150px',
    },
  ];

  const downloadCSV = () => {
    downloadFile(
      '/@osservazioni-csv',
      { usageKey: data.key },
      intl,
      (status) => {
        setDownloadCSVStatus(status);
      },
    );
  };

  // const downloadElastic = () => {
  //   downloadFile(
  //     data.watchings_download_url?.[0],
  //     null,
  //     intl,
  //     (status) => {
  //       setDownloadElasticStatus(status);
  //     },
  //     true,
  //     'observations_' +
  //       data.canonicalName?.toLowerCase()?.replace(' ', '_') +
  //       '.csv',
  //   );
  // };

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const filtered =
      watchings?.result?.filter((r) => {
        let ret = true;
        if (filters.canonicalName || filters.year || filters.datasource) {
          if (
            filters.canonicalName &&
            r.canonicalName !== filters.canonicalName
          ) {
            ret = false;
          }

          if (filters.year && r.year?.toString() !== filters.year) {
            ret = false;
          }

          if (filters.datasource && r.datasource !== filters.datasource) {
            ret = false;
          }

          return ret;
        }
        return ret;
      }) ?? [];

    setFilteredData(filtered);
  }, [watchings, filters]);

  const filtersComponent = React.useMemo(() => {
    return (
      <>
        <div className="filters">
          {[
            {
              id: 'canonicalname',
              label: intl.formatMessage(messages.canonicalName),
            },
            { id: 'year', label: intl.formatMessage(messages.year) },
            {
              id: 'datasource',
              label: intl.formatMessage(messages.datasource),
            },
          ].map((f) => {
            return (
              <div className="filter">
                <select
                  onBlur={() => {}}
                  onChange={(e) => onChangeFilter(e, f)}
                  value={filters[f.id] ?? null}
                  aria-label={
                    intl.formatMessage(messages.filtra_per) + ' ' + f.label
                  }
                >
                  <option value="" hidden>
                    {intl.formatMessage(messages.filtra_per) + ' ' + f.label}
                  </option>
                  <option value=""></option>

                  {watchings.result?.length > 0 ? (
                    [...new Set(watchings?.result?.map((item) => item[f.id]))]
                      .filter((o) => o != null)
                      .map((o) => (
                        <option key={o} value={o}>
                          {/<a[^>]*>([^<]+)<\/a>/g.exec(o)?.[1] ?? o}
                        </option>
                      ))
                  ) : (
                    <></>
                  )}
                </select>
              </div>
            );
          })}
        </div>
        {watchings.loaded && (
          <div className="info-text">
            {intl.formatMessage(messages.max_items_info)}{' '}
            {data.geoviewer_link && (
              <UniversalLink href={data.geoviewer_link} className="m-2">
                <Icon icon="it-external-link" padding={false} />
                {intl.formatMessage(messages.geoviewer_link)}
              </UniversalLink>
            )}
          </div>
        )}
      </>
    );
  }, [filters, watchings?.result]);

  const toggleModalElastic = () => {
    setModalElasticOpen(!modalElasticOpen);
  };

  return watchingsUrl ? (
    <div className="watchings mt-3 mb-5">
      <h3>{intl.formatMessage(messages.Watchings)}</h3>

      {watchings.loadingResults && (
        <div className="mt-5 mb-5 loading">
          <Spinner active double={false} small={false} tag="span" />
          <div>{intl.formatMessage(messages.loading)}</div>
        </div>
      )}

      {watchings.result?.length > 0 ? (
        <>
          {!watchings.loadingResults && (
            <div className="d-flex">
              <Button
                color="light"
                size="xs"
                className="m-2 d-flex"
                onClick={() => downloadCSV()}
                disabled={downloadCSVStatus.loading}
                icon
              >
                {downloadCSVStatus.loading && (
                  <Spinner
                    active
                    double={false}
                    tag="span"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                <Icon icon="it-download" padding={false} />
                {intl.formatMessage(messages.downloadCSV)}
              </Button>

              {data.watchings_download_url?.[0] && (
                <>
                  {/* <Button
                    color="light"
                    size="xs"
                    className="m-2 d-flex"
                    onClick={() => downloadElastic()}
                    disabled={downloadElasticStatus.loading}
                    icon
                  >
                    {downloadElasticStatus.loading && (
                      <Spinner
                        active
                        double={false}
                        tag="span"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    <Icon icon="it-download" padding={false} />
                    {intl.formatMessage(messages.downloadElastic)}
                  </Button>
                  <div className="download-infos">
                    {intl.formatMessage(messages.downloadElasticInfos)}
                  </div> */}

                  <Button
                    color="light"
                    size="xs"
                    className="m-2 d-flex"
                    onClick={() => toggleModalElastic()}
                    icon
                  >
                    <Icon icon="it-download" padding={false} />
                    {intl.formatMessage(messages.downloadElastic)}
                  </Button>
                  <Modal
                    isOpen={modalElasticOpen}
                    toggle={() => toggleModalElastic()}
                    centered
                    labelledBy="modalElasticTitle"
                  >
                    <ModalHeader
                      toggle={() => toggleModalElastic()}
                      id="modalElasticTitle"
                    >
                      {intl.formatMessage(messages.modalElasticDownloadHeader)}
                    </ModalHeader>
                    <ModalBody>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage(
                            messages.modalElasticDownloadBody,
                            {
                              link:
                                '<a href="' +
                                data.watchings_download_url +
                                '" target="_blank">' +
                                data.watchings_download_url +
                                '</a>',
                            },
                          ),
                        }}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="secondary"
                        onClick={() => toggleModalElastic()}
                      >
                        {intl.formatMessage(messages.close)}
                      </Button>
                    </ModalFooter>
                  </Modal>
                </>
              )}
            </div>
          )}

          {downloadCSVStatus.error && (
            <ViewError error={downloadCSVStatus.error} />
          )}

          <DataTable
            columns={columns}
            data={filteredData ?? []}
            striped={true}
            highlightOnHover={true}
            pointerOnHover={false}
            noDataComponent={intl.formatMessage(messages.no_data)}
            noHeader={true}
            responsive={true}
            defaultSortField={DEFAULT_SORT_ON}
            defaultSortAsc={DEFAULT_SORT_ORDER === 'ascending'}
            pagination={true}
            paginationComponentOptions={{ noRowsPerPage: true }}
            progressPending={watchings.loadingResults}
            progressComponent={() => <></>}
            subHeader
            subHeaderComponent={filtersComponent}
            // onSort={(column, direction) =>
            //   setSorting(column.selector, direction)
            // }
          />
        </>
      ) : (
        ''
      )}
    </div>
  ) : null;
};

export default Watchings;
