/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDF5_2 = ({ data }) => {
  return (
    <>
      <h2>5.2 Relation of the described site with other sites:</h2>
      <p className="mapLegend more-margin">
        Designated at national or regional level:
      </p>
      <table className="SDFtable3rd no-border-collapse MinimalHeight">
        <tbody>
          <tr>
            <th width="22%">Type code</th>
            <th width="57%%">Site name</th>
            <th width="9%">Type</th>
            <th width="12%">Cover [%]</th>
          </tr>
          {data.site_relation?.map((sr) => (
            <tr key={sr.site_relation_code}>
              <td className="CellBorder">{sr.site_relation_code}</td>
              <td className="CellBorder">{sr.site_relation_sitename}</td>
              <td className="CellBorder">{sr.site_relation_type}</td>
              <td className="CellBorder">{sr.site_relation_cover}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default SDF5_2;
