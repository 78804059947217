export const viewValue = (value) => {
  if (value && (value.text != null || value.desc != null)) {
    let text =
      value.text === 'true'
        ? 'Yes'
        : value.text === 'false'
        ? 'No'
        : value.text;

    if (text != null && value.desc != null && value.desc?.length > 0) {
      return text + ' - ' + value.desc;
    } else {
      return text || value.desc;
    }
  }
  return null;
};

export const isEmpty = (value) => {
  if (!value || (!value.text && !value.desc) || value.text === 'false') {
    return true;
  }

  return false;
};

export const getArrayItems = (array) => {
  let ret = [];

  ret = array?.map((a) => {
    return getObjectProps(a);
  });

  return ret;
};

export const getObjectProps = (array) => {
  if (array?.length > 0) {
    let o = {};
    array.forEach((i) => {
      o[i.tag] = { ...i };
      delete o[i.tag].tag;
    });
    return o;
  }
  return null;
};
