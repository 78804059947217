/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDFToc = ({ data }) => {
  return (
    <div className="table-of-contents">
      <h1>TABLE OF CONTENTS</h1>
      <ul>
        <li>
          <a href="#1">1. SITE IDENTIFICATION</a>
        </li>
        <li>
          <a href="#2">2. SITE LOCATION</a>
        </li>
        <li>
          <a href="#3">3. ECOLOGICAL INFORMATION</a>
        </li>
        <li>
          <a href="#4">4. SITE DESCRIPTION</a>
        </li>
        <li>
          <a href="#5">5. SITE PROTECTION STATUS</a>
        </li>
        <li>
          <a href="#6">6. SITE MANAGEMENT</a>
        </li>
        <li>
          <a href="#7">7. MAP OF THE SITE</a>
        </li>
      </ul>
    </div>
  );
};

export default SDFToc;
