/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDF4_4 = ({ data }) => {
  return (
    <>
      <h2>4.4 Ownership (optional)</h2>
      <table className="SDFtable3rd HalfWidthTable no-border-collapse">
        <tbody>
          <tr>
            <th colspan="2" className="DivisorRight">
              Type
            </th>
            <th>[%]</th>
          </tr>

          {data.site_ownership
            ?.filter((o) => o.ownership_code.startsWith('public'))
            .map((oc, i) => (
              <tr key={'own' + i}>
                {i === 0 && (
                  <td
                    width="10%"
                    rowspan={
                      data.site_ownership?.filter((o) =>
                        o.ownership_code.startsWith('public'),
                      )?.length || 1
                    }
                    className="DivisorTop DivisorRight"
                  >
                    Public
                  </td>
                )}
                <td width="50%" className="DivisorTop DivisorRight">
                  {oc.ownership_type}
                </td>
                <td width="40%" className="DivisorTop">
                  {oc.ownership_percent || 0}
                </td>
              </tr>
            ))}

          {data.site_ownership
            ?.filter((o) => !o.ownership_code.startsWith('public'))
            .map((oc, ii) => (
              <tr key={'siteown' + ii}>
                <td colspan="2" className="DivisorTop DivisorRight">
                  {oc.ownership_type}
                </td>
                <td className="DivisorTop">{oc.ownership_percent || 0}</td>
              </tr>
            ))}

          <tr>
            <td colspan="2" className="DivisorTop DivisorRight">
              sum
            </td>
            <td className="DivisorTop">
              {data.site_ownership.reduce(
                (acc, v) => acc + (v.ownership_percent || 0),
                0,
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SDF4_4;
