import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import moment from 'moment';
import { Spinner, Button } from 'design-react-kit/dist/design-react-kit';
import { Chart } from 'react-charts';
import { Icon } from '@italia/components/ItaliaTheme';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getPollNetData } from '@package/actions';

const messages = defineMessages({
  pollnet_title: {
    id: 'pollnet_title',
    defaultMessage: 'Collegamento con rete PollNet',
  },
  pollnet_card_link: {
    id: 'pollnet_card_link',
    defaultMessage: 'Scheda specie su PollNet',
  },
  view_pollnet_data: {
    id: 'pollnet_view_data',
    defaultMessage: 'Vedi i dati PollNet',
  },
  loadingResults: {
    id: 'pollnet_loading',
    defaultMessage: 'Sto scaricando i dati da PollNet...',
  },
  chart_title: {
    id: 'pollnet_cart_title',
    defaultMessage: 'Concentrazioni medie dei pollini negli ultimi 15 giorni',
  },
});

const PollNetData = ({ data }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  moment.locale(intl.locale);
  const pollNetURL =
    data?.['@components']?.pollnet_data?.length > 0
      ? flattenToAppURL(data?.['@components'].pollnet_data)
      : null;
  const [chartData, setChartData] = useState(null);

  const pollnetData = useSelector((state) => {
    return state.pollnetData.subrequests?.[pollNetURL] ?? {};
  });

  const loadPollNetData = () => {
    dispatch(getPollNetData(pollNetURL));
  };

  useEffect(() => {
    let days = [];

    for (let i = 15; i > 0; i--) {
      let d = moment().add(-i, 'days').startOf('day').toDate().getTime();
      days.push(d);
    }

    let chart_data = [];
    if (pollnetData?.result?.length > 0) {
      pollnetData.result.forEach((d) => {
        let chartSeries = { datums: [], label: d.stazione };
        d.concentrazione.forEach((c, c_index) => {
          chartSeries.datums.push({ x: days[c_index], y: c });
        });
        chart_data.push(chartSeries);
      });
      setChartData(chart_data);
    }
  }, [pollnetData.result]);

  const axes = React.useMemo(
    () => [
      {
        primary: true,
        type: 'ordinal',
        position: 'bottom',
        format: (f) => {
          return moment(new Date(f)).format('YYYY MMM DD');
        },
      },
      { type: 'linear', position: 'left' },
    ],
    [],
  );

  return data?.pollnet_card_link || pollNetURL ? (
    <div className="pollnet-data mt-3 mb-5">
      <h3>{intl.formatMessage(messages.pollnet_title)}</h3>
      {(data.pollnet_card_link || (pollNetURL && !pollnetData.loaded)) && (
        <div className="mb-3">
          {pollNetURL && !pollnetData.loaded && (
            <Button
              color="primary"
              size="xs"
              onClick={() => loadPollNetData()}
              disabled={pollnetData.loadingResults}
              className="m-2"
            >
              {intl.formatMessage(messages.view_pollnet_data)}
            </Button>
          )}
          {data.pollnet_card_link && (
            <Button
              tag={UniversalLink}
              href={data.pollnet_card_link}
              className="m-2"
              outline
              color="primary"
              icon
              size="xs"
            >
              <Icon icon="it-external-link" padding={false} />
              {intl.formatMessage(messages.pollnet_card_link)}
            </Button>
          )}
        </div>
      )}

      {pollnetData.loadingResults && (
        <div className="mt-3 mb-5 loading">
          <Spinner active double={false} small={false} tag="span" />
          <div>{intl.formatMessage(messages.loadingResults)}</div>
        </div>
      )}
      {chartData?.length > 0 && (
        <div className="mt-4 mb-5">
          <h5 className="text-center">
            {intl.formatMessage(messages.chart_title)}
          </h5>
          <div style={{ width: '100%', height: '400px' }}>
            <Chart
              data={chartData}
              axes={axes}
              series={{ showPoints: true }}
              tooltip
            />
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default PollNetData;
