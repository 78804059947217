import React from 'react';
import { useIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';

const getCatalogLink = (usageKey, locale) => {
  return `${locale ? '/' + locale : ''}/data/${usageKey}`;
};
const CatalogLink = (props) => {
  let p = { ...props };
  delete p.usageKey;
  const intl = useIntl();
  return (
    props.usageKey && (
      <UniversalLink
        {...p}
        href={getCatalogLink(props.usageKey, intl.locale)}
      />
    )
  );
};

export { CatalogLink, getCatalogLink };
