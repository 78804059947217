export const GET_ART12 = 'GET_ART12';

export function getArt12(name, season) {
  return {
    type: GET_ART12,
    request: {
      op: 'get',
      path: `/@art12?name=${name}&season=${season}`,
    },
  };
}
