/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { UniversalLink } from '@plone/volto/components';
import infoImg from '~/components/Layout/images/info_sdf.jpeg';

import { UncontrolledTooltip } from 'design-react-kit/dist/design-react-kit';

const SDF3_1 = ({ data }) => {
  // const viewHabitatInfos = (code) => {
  //   window.open(
  //     'https://natura2000.eea.europa.eu/Natura2000/HabCodePopup.aspx?habcode=' +
  //       code,
  //     'habpopup',
  //     'height=130,width=300,toolbar=no,scrollbars=yes',
  //   );
  // };

  return (
    <>
      <h2>3.1 Habitat types present on the site and assessment for them</h2>
      <table className="SDFtable3rd no-border-collapse">
        <thead>
          <tr>
            <th colspan="6" className="MinimalHeight DivisorRight">
              Annex I Habitat types
            </th>
            <th colspan="4">Site assessment</th>
          </tr>
          <tr>
            <th className="DivisorTop DivisorRight">Code</th>
            <th className="DivisorTop DivisorRight">PF</th>
            <th className="DivisorTop DivisorRight">NP</th>
            <th className="DivisorTop DivisorRight">Cover [ha]</th>
            <th className="DivisorTop DivisorRight">Cave [number]</th>
            <th className="DivisorTop DivisorRight">Data quality</th>
            <th className="DivisorTop DivisorRight">A|B|C|D</th>
            <th className="DivisorTop" colspan="3">
              A|B|C
            </th>
          </tr>
          <tr>
            <th className="DivisorTop DivisorRight" width="7.5%"></th>
            <th className="DivisorTop DivisorRight" width="5%"></th>
            <th className="DivisorTop DivisorRight" width="5%"></th>
            <th className="DivisorTop DivisorRight" width="15%"></th>
            <th className="DivisorTop DivisorRight" width="7.5%"></th>
            <th className="DivisorTop DivisorRight" width="5%"></th>
            <th className="DivisorTop DivisorRight" width="10%">
              Representativity
            </th>
            <th className="DivisorTop DivisorRight" width="15%">
              Relative Surface
            </th>
            <th className="DivisorTop DivisorRight" width="15%">
              Conservation
            </th>
            <th className="DivisorTop " width="15%">
              Global
            </th>
          </tr>
        </thead>
        <tbody>
          {data.habitat.map((h) => {
            const habitatInfoId = 'habitatinfo' + h.habitat_code;
            return (
              <tr key={h.habitat_code}>
                <td className="DivisorTop DivisorRight">
                  <UniversalLink href={h.code_url}>
                    {h.habitat_code}
                  </UniversalLink>

                  {/* <button
                  onClick={() => viewHabitatInfos(h.habitat_code)}
                  onKeyDown={() => viewHabitatInfos(h.habitat_code)}
                  title="Habitat info"
                  className="invisible-button margin-left-1"
                >
                  <img
                    alt="info"
                    src={infoImg}
                    className="button_class"
                    id="habcode1"
                  />
                </button> */}

                  <button
                    id={habitatInfoId}
                    type="button"
                    title="Habitat info"
                    className="invisible-button margin-left-1"
                  >
                    <img
                      alt="info"
                      src={infoImg}
                      className="button_class"
                      id="habcode1"
                    />
                  </button>

                  <UncontrolledTooltip placement="right" target={habitatInfoId}>
                    {h.description}
                  </UncontrolledTooltip>
                </td>
                <td className="DivisorTop DivisorRight">
                  {h.habitat_priority ? 'X' : ''}
                </td>
                <td className="DivisorTop DivisorRight">{h.habitat_np}</td>
                <td className="DivisorTop DivisorRight">
                  {h.habitat_cover_ha}
                </td>
                <td className="DivisorTop DivisorRight">
                  {Number(h.habitat_caves).toFixed(2)}
                </td>
                <td className="DivisorTop DivisorRight">
                  {h.habitat_data_quality}
                </td>
                <td className="DivisorTop DivisorRight">
                  {h.habitat_representativity}
                </td>
                <td className="DivisorTop DivisorRight">
                  {h.habitat_relative_surface}
                </td>
                <td className="DivisorTop DivisorRight">
                  {h.habitat_conservation}
                </td>
                <td className="DivisorTop">{h.habitat_global}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ul className="legend">
        <li>
          <b>PF:</b> for the habitat types that can have a non-priority as well
          as a priority form (6210, 7130, 9430) enter "X" in the column PF to
          indicate the priority form.
        </li>
        <li>
          <b>NP:</b> in case that a habitat type no longer exists in the site
          enter: x (optional)
        </li>
        <li>
          <b>Cover:</b> decimal values can be entered
        </li>
        <li>
          <b>Caves:</b> for habitat types 8310, 8330 (caves) enter the number of
          caves if estimated surface is not available.
        </li>
        <li>
          <b>Data quality:</b> G = 'Good' (e.g. based on surveys); M =
          'Moderate' (e.g. based on partial data with some extrapolation); P =
          'Poor' (e.g. rough estimation)
        </li>
      </ul>
    </>
  );
};

export default SDF3_1;
