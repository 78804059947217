/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDF1_2 = ({ data }) => {
  return (
    <>
      <h2>1.2 Site code</h2>
      <table className="SDFtable MinimalHeight">
        <tbody>
          <tr>
            <td>{data.site_code}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SDF1_2;
