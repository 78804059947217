import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { CatalogLink } from '@package/components/Views/Catalog/CatalogLink';

const messages = defineMessages({
  classification: {
    id: 'Classification',
    defaultMessage: 'Classificazione',
  },
  kingdom: {
    id: 'Kingdom',
    defaultMessage: 'Kinkgdom',
  },
  phylum: { id: 'tax_Phylum', defaultMessage: 'Phylum' },
  class: { id: 'tax_Class', defaultMessage: 'Class' },
  order: { id: 'tax_Order', defaultMessage: 'Order' },
  family: { id: 'tax_Family', defaultMessage: 'Family' },
  genus: { id: 'tax_Genus', defaultMessage: 'Genus' },
  species: { id: 'tax_Species', defaultMessage: 'Species' },
});

const Classification = ({ data }) => {
  const intl = useIntl();

  const taxonomies = [
    'kingdom',
    'phylum',
    'class',
    'order',
    'family',
    'genus',
    'species',
  ];

  let dataTaxonomies = [];
  taxonomies.forEach((t) => {
    if (data[t]) {
      dataTaxonomies.push({
        label: messages[t] ? intl.formatMessage(messages[t]) : t,
        value: data[t],
        dataKey: data[t + 'Key'],
        t: t,
      });
    }
  });
  return (
    data && (
      <div className="classification">
        <div className="classification-header">
          {intl.formatMessage(messages.classification)}
        </div>
        <div className="classification-body">
          {dataTaxonomies.map((t) => (
            <div className="taxonomy" key={t.t}>
              <div className="title">{t.label}: </div>
              <div className="value">
                {t.dataKey ? (
                  <CatalogLink usageKey={t.dataKey}>{t.value} </CatalogLink>
                ) : (
                  t.value
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default Classification;
