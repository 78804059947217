/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDFSection = ({ id, title, children }) => {
  return (
    <div className="sdf-section" id={id}>
      <h1>{title}</h1>
      <a href="#top" className="BackTopLink">
        Back to top
      </a>

      {children}
    </div>
  );
};

export default SDFSection;
