import axios from 'axios';
import { saveAs } from 'file-saver';
import { defineMessages } from 'react-intl';
import config from '@plone/volto/registry';

const messages = defineMessages({
  url_not_found: {
    id: 'Url not found. Unable to download this file.',
    defaultMessage: 'Url non trovato. Impossibile scaricare il file',
  },
  permission_denied: {
    id: 'You do not have permission to download this file.',
    defaultMessage: 'Non hai i permessi per scaricare questo file',
  },
  error: {
    id: 'An error occurred downloading file.',
    defaultMessage: 'Errore durante il download del file',
  },
});

const apiFetch = ({ url, params, method }) => {
  if (!method) {
    method = 'GET';
  }
  var headers = {
    Accept: 'application/json',
  };

  return axios({
    method,
    url,
    params: method === 'GET' ? params : null,
    data: ['POST', 'PATCH'].indexOf(method) >= 0 ? params : null,
    //paramsSerializer: params => parseParams(params),
    headers,
    responseType: 'arraybuffer',
  });
};

export const downloadFile = (
  url,
  params,
  intl,
  setStatus,
  forceAttachment = false,
  filename,
) => {
  const { settings } = config;

  const apiPath = url?.startsWith('http')
    ? ''
    : (settings.internalApiPath || settings.apiPath) + '/++api++';
  setStatus({ loading: true, error: null });

  apiFetch({
    url: apiPath + url,
    params: params,
    method: 'GET',
  })
    .then((res) => {
      if (!res) {
        setStatus({
          loading: false,
          loaded: false,
          error: {
            status: 404,
            message: intl.formatMessage(messages.url_not_found),
          },
        });
        return;
      }
      if (res.status !== 200) {
        switch (res.status) {
          case 401:
          case 403:
            setStatus({
              loading: false,
              loaded: false,
              error: {
                status: res.status,
                message: intl.formatMessage(messages.permission_denied),
              },
            });
            return;
          default:
            setStatus({
              loading: false,
              loaded: false,
              error: {
                status: res.status,
                message: intl.formatMessage(messages.error),
              },
            });
            return;
        }
      }
      if (!res.data || res.data.length === 0) {
        setStatus({
          loading: false,
          loaded: false,
          error: {
            status: res.status,
            message: intl.formatMessage(messages.error),
          },
        });
        return;
      }
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const disposition =
        res.headers['content-disposition'] ||
        (forceAttachment ? 'attachment' : null);

      setStatus({ loading: false, loaded: true, error: null });

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const matches = filenameRegex.exec(disposition);
        const _filename = filename ?? matches[1].replace(/['"]/g, '');

        const blob = new Blob([res.data], {
          type: res.headers['content-type'],
        });

        saveAs(blob, _filename);
      }
    })
    .catch((err) => {
      //console.error(err);
      setStatus({
        loading: false,
        loaded: false,
        error: {
          status: 500,
          message: err.message,
        },
      });
    });
};
