/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDF4_2 = ({ data }) => {
  return (
    <>
      <h2>4.2 Quality and importance</h2>
      <table className="SDFtable MinimalHeight">
        <tbody>
          <tr>
            <td>{data.site_quality}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SDF4_2;
