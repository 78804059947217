/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDF4_3 = ({ data }) => {
  return (
    <>
      <h2>4.3 Threats, pressures and activities with impacts on the site</h2>
      <p className="more-margin">
        The most important impacts and activities with high effect on the site
      </p>
      <table className="SDFtable3rd HalfWidthTable no-border-collapse">
        <tbody>
          <tr>
            <th colspan="4">Negative Impacts</th>
          </tr>
          <tr>
            <th width="8%" className="DivisorTop DivisorRight">
              Rank
            </th>
            <th width="22%" className="DivisorTop DivisorRight">
              Threats and pressures [code]
            </th>
            <th width="10%" className="DivisorTop DivisorRight">
              Pollution (optional) [code]
            </th>
            <th className="DivisorTop">inside/outside [i|o|b]</th>
          </tr>
          {data.impact?.negative?.map((n) => (
            <tr key={n.impact_code}>
              <td className="DivisorTop DivisorRight">{n.impact_rank}</td>
              <td className="DivisorTop DivisorRight">{n.impact_code}</td>
              <td className="DivisorTop DivisorRight">
                {n.impact_pollution_code}
              </td>
              <td className="DivisorTop">{n.impact_occurrence}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <table className="SDFtable3rd HalfWidthTable no-border-collapse">
        <tbody>
          <tr>
            <th colspan="4">Positive Impacts</th>
          </tr>
          <tr>
            <th width="8%" className="DivisorTop DivisorRight">
              Rank
            </th>
            <th width="22%" className="DivisorTop DivisorRight">
              Activities, management [code]
            </th>
            <th width="10%" className="DivisorTop DivisorRight">
              Pollution (optional) [code]
            </th>
            <th className="DivisorTop">inside/outside [i|o|b]</th>
          </tr>
          {data.impact?.positive?.map((n) => (
            <tr>
              <td className="DivisorTop DivisorRight">{n.impact_rank}</td>
              <td className="DivisorTop DivisorRight">{n.impact_code}</td>
              <td className="DivisorTop DivisorRight">
                {n.impact_pollution_code}
              </td>
              <td className="DivisorTop">{n.impact_occurrence}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="mapLegend more-margin">
        Rank: H = high, M = medium, L = low <br />
        Pollution: N = Nitrogen input, P = Phosphor/Phosphate input, A = Acid
        input/acidification,
        <br />
        T = toxic inorganic chemicals, O = toxic organic chemicals, X = Mixed
        pollutions
        <br />i = inside, o = outside, b = both
      </p>
    </>
  );
};

export default SDF4_3;
