/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { UniversalLink } from '@plone/volto/components';

const SDF3_2 = ({ data }) => {
  return (
    <>
      <h2>
        3.2 Species referred to in Article 4 of Directive 2009/147/EC and listed
        in Annex II of Directive 92/43/EEC and site evaluation for them
      </h2>
      <table className="SDFtable3rd no-border-collapse" width="100%">
        <thead>
          <tr>
            <th colspan="5" className="MinimalHeight DivisorRight">
              Species
            </th>
            <th colspan="6" className="DivisorRight">
              Population in the site
            </th>
            <th colspan="4">Site assessment</th>
          </tr>
          <tr>
            <th className="DivisorTop DivisorRight">G</th>
            <th className="DivisorTop DivisorRight">Code</th>
            <th className="DivisorTop DivisorRight">Scientific Name</th>
            <th className="DivisorTop DivisorRight">S</th>
            <th className="DivisorTop DivisorRight">NP</th>
            <th className="DivisorTop DivisorRight">T</th>
            <th className="DivisorTop DivisorRight" colspan="2">
              Size
            </th>
            <th className="DivisorTop DivisorRight">Unit</th>
            <th className="DivisorTop DivisorRight">Cat.</th>
            <th className="DivisorTop DivisorRight">D.qual.</th>
            <th className="DivisorTop DivisorRight">A|B|C|D</th>
            <th className="DivisorTop DivisorRight" colspan="3">
              A|B|C
            </th>
          </tr>
          <tr>
            <th className="DivisorTop DivisorRight" width="5%"></th>
            <th className="DivisorTop DivisorRight" width="5%"></th>
            <th className="DivisorTop DivisorRight" width="15%"></th>
            <th className="DivisorTop DivisorRight" width="5%"></th>
            <th className="DivisorTop DivisorRight" width="5%"></th>
            <th className="DivisorTop DivisorRight" width="5%"></th>
            <th className="DivisorTop DivisorRight" width="7.5%">
              Min
            </th>
            <th className="DivisorTop DivisorRight" width="7.5%">
              Max
            </th>
            <th className="DivisorTop DivisorRight" width="5%"></th>
            <th className="DivisorTop DivisorRight" width="5%"></th>
            <th className="DivisorTop DivisorRight" width="5%"></th>
            <th className="DivisorTop DivisorRight" width="7.5%">
              Pop.
            </th>
            <th className="DivisorTop DivisorRight" width="7.5%">
              Con.
            </th>
            <th className="DivisorTop DivisorRight" width="7.5%">
              Iso.
            </th>
            <th className="DivisorTop DivisorRight" width="7.5%">
              Glo.
            </th>
          </tr>
        </thead>
        <tbody>
          {data.species?.map((s, i) => (
            <tr key={'s' + s.species_code}>
              <td className="DivisorTop DivisorRight">{s.species_group}</td>
              <td className="DivisorTop DivisorRight">
                <UniversalLink href={s.code_url}>
                  {s.species_code}
                </UniversalLink>
              </td>
              <td className="DivisorTop DivisorRight">
                <UniversalLink href={s.name_url}>
                  {s.species_name}
                </UniversalLink>
              </td>
              <td className="DivisorTop DivisorRight">{s.species_sensitive}</td>
              <td className="DivisorTop DivisorRight">{s.species_np}</td>
              <td className="DivisorTop DivisorRight">{s.species_type}</td>
              <td className="DivisorTop DivisorRight">{s.species_size_min}</td>
              <td className="DivisorTop DivisorRight">{s.species_size_max}</td>
              <td className="DivisorTop DivisorRight">{s.species_unit}</td>
              <td className="DivisorTop DivisorRight">{s.species_category}</td>
              <td className="DivisorTop DivisorRight">
                {s.species_data_quality}
              </td>
              <td className="DivisorTop DivisorRight">
                {s.species_population}
              </td>
              <td className="DivisorTop DivisorRight">
                {s.species_conservation}
              </td>
              <td className="DivisorTop DivisorRight">{s.species_isolation}</td>
              <td className="DivisorTop">{s.species_global}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ul className="legend">
        <li>
          <b>Group:</b> A = Amphibians, B = Birds, F = Fish, I = Invertebrates,
          M = Mammals, P = Plants, R = Reptiles
        </li>
        <li>
          <b>S:</b> in case that the data on species are sensitive and therefore
          have to be blocked for any public access enter: yes
        </li>
        <li>
          <b>NP:</b> in case that a species is no longer present in the site
          enter: x (optional)
        </li>
        <li>
          <b>Type:</b> p = permanent, r = reproducing, c = concentration, w =
          wintering (for plant and non-migratory species use permanent)
        </li>
        <li>
          <b>Unit:</b> i = individuals, p = pairs or other units according to
          the Standard list of population units and codes in accordance with
          Article 12 and 17 reporting (see{' '}
          <a href="https://bd.eionet.europa.eu/activities/Natura_2000/reference_portal">
            reference portal
          </a>
          )
        </li>
        <li>
          <b>Abundance categories (Cat.):</b> C = common, R = rare, V = very
          rare, P = present - to fill if data are deficient (DD) or in addition
          to population size information
        </li>
        <li>
          <b>Data quality:</b> G = 'Good' (e.g. based on surveys); M =
          'Moderate' (e.g. based on partial data with some extrapolation); P =
          'Poor' (e.g. rough estimation); VP = 'Very poor' (use this category
          only, if not even a rough estimation of the population size can be
          made, in this case the fields for population size can remain empty,
          but the field "Abundance categories" has to be filled in)
        </li>
      </ul>
    </>
  );
};

export default SDF3_2;
