import React from 'react';
//eslint-disable-next-line import/no-unresolved
import logo from '~/components/Layout/images/logo.png?width=250';

/*
 * Customization with image
 *
 * If you have a jpg/png, do like this:
 *
 * <figure className="icon">
 *  <img src={logo} alt="" />
 * </figure>
 *
 * Note the icon class.
 */

const Logo = () => (
  <img
    src={logo}
    width="250"
    height="140"
    alt="Network Nazionale Biodiversità Logo"
    className="icon"
  />
);

export default Logo;
