import React from 'react';

import { Alert } from 'design-react-kit/dist/design-react-kit';

const ViewError = ({ error }) =>
  error ? (
    <Alert
      color="danger"
      fade
      isOpen
      tag="div"
      transition={{
        appear: true,
        baseClass: 'fade',
        baseClassActive: 'show',
        enter: true,
        exit: true,
        in: true,
        mountOnEnter: false,
        onEnter: function noRefCheck() {},
        onEntered: function noRefCheck() {},
        onEntering: function noRefCheck() {},
        onExit: function noRefCheck() {},
        onExited: function noRefCheck() {},
        onExiting: function noRefCheck() {},
        tag: 'div',
        timeout: 150,
        unmountOnExit: true,
      }}
      className="my-4"
    >
      <h2>Error {error.status}</h2>
      <div className="mb-3">{error.message}</div>
      {error.stack && <div>{error.stack}</div>}
    </Alert>
  ) : null;

export default ViewError;
