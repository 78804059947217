/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDF1_1 = ({ data }) => {
  return (
    <>
      <h2>1.1 Type</h2>
      <table className="SDFtable MinimalHeight">
        <tbody>
          <tr>
            <td>{data.site_type}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SDF1_1;
