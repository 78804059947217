/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Spinner,
  //Badge,
  Button,
} from 'design-react-kit/dist/design-react-kit';
import { Helmet } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';
import { Icon } from '@italia/components/ItaliaTheme';

import { getCatalogData } from '@package/actions';
import Classification from '@package/components/Views/Catalog/Classification';
import { CatalogLink } from '@package/components/Views/Catalog/CatalogLink';
import ConservationStatus from '@package/components/Views/Catalog/ConservationStatus';
import Images from '@package/components/Views/Catalog/Images';
import Natura2000Sites from '@package/components/Views/Catalog/Natura2000Sites';
import Art12 from '@package/components/Views/Catalog/Art12';
import Watchings from '@package/components/Views/Catalog/Watchings';
import ViewError from '@package/components/Views/Catalog/ViewError';
import PollNetData from '@package/components/Views/Catalog/PollNetData';
import CitizenScienceData from '@package/components/Views/Catalog/CitizenScienceData';
import ChildrenItems from '@package/components/Views/Catalog/ChildrenItems';

const messages = defineMessages({
  basionym: {
    id: 'Basionym',
    defaultMessage: 'Basionym',
  },
  scientificName: {
    id: 'Scientific name',
    defaultMessage: 'Scientific name',
  },
  infraspeces: {
    id: 'Infraspeces',
    defaultMessage: 'Infraspeces',
  },
  classification: {
    id: 'Classification',
    defaultMessage: 'Classification',
  },

  downloadBiocaseFormat: {
    id: 'downloadBiocaseFormat',
    defaultMessage: 'Download dati nel formato Biocase',
  },
  viewGbifInfo: {
    id: 'viewGbifInfo',
    defaultMessage: 'Vedi le informazioni sul sito GBIF',
  },
  geoviewer_link: {
    id: 'Geoviewer link',
    defaultMessage: 'Vedi sulla mappa',
  },
  loading: {
    id: 'Catalog_loading',
    defaultMessage: 'Sto caricando i dati...',
  },
});

const CatalogView = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const usageKey = props?.match?.params?.[1];

  const data = useSelector((state) => {
    return state.catalogData?.result;
  });

  const catalogData = useSelector((state) => {
    return state.catalogData;
  });

  useEffect(() => {
    dispatch(getCatalogData(usageKey));
  }, [usageKey]);

  return (
    <div id="page-view-catalog" className="ui container">
      {catalogData.hasError ? (
        <ViewError error={catalogData.error} />
      ) : (
        <>
          <Helmet title={data.canonicalName} />
          {catalogData.loadingResults ? (
            <Container>
              <div className="mt-5 mb-5 loading">
                <Spinner active double={false} small={false} tag="span" />
                <div>{intl.formatMessage(messages.loading)}</div>
              </div>
            </Container>
          ) : (
            <>
              {/*-----Testata-----*/}
              <div className="full-width PageHeaderWrapper pt-5 pb-2 mb-5">
                <Container className="px-3 px-md-4 mb-4">
                  <div className="row">
                    <div className="title-description-wrapper col-lg-8">
                      {(data.rank || data.taxonomicStatus) && (
                        <div className="category">
                          <span>{data.rank}</span>{' '}
                          <span>{data.taxonomicStatus}</span>
                        </div>
                      )}
                      <h1 className="mb-3">{data.canonicalName}</h1>
                      <div className="header-data mb-3">
                        {data.basionym && (
                          <div>
                            <span className="font-weight-bold">
                              {intl.formatMessage(messages.basionym)}:{' '}
                            </span>
                            <CatalogLink usageKey={data.basionymKey}>
                              {data.basionym}
                            </CatalogLink>
                          </div>
                        )}
                        {data.scientificName && (
                          <div>
                            <span className="font-weight-bold">
                              {intl.formatMessage(messages.scientificName)}:{' '}
                            </span>

                            {data.scientificName}
                          </div>
                        )}
                      </div>

                      <ConservationStatus data={data} />
                    </div>
                    <div className="col-lg-4">
                      <Classification data={data} />
                      {/* {data.numDescendants && (
                        <Badge
                          color="light"
                          pill={false}
                          tag={CatalogLink}
                          usageKey={data.nubKey}
                        >
                          {data.numDescendants}{' '}
                          {intl.formatMessage(messages.infraspeces)}
                        </Badge>
                      )} */}
                    </div>
                  </div>
                </Container>
              </div>

              <Container className="data-content">
                <div className="links mb-4">
                  {data.geoviewer_link && (
                    <Button
                      tag={UniversalLink}
                      href={data.geoviewer_link}
                      className="m-2"
                      outline
                      color="primary"
                      icon
                    >
                      <Icon icon="it-external-link" padding={false} />
                      {intl.formatMessage(messages.geoviewer_link)}
                    </Button>
                  )}

                  {data.gbif_link && (
                    <Button
                      tag={UniversalLink}
                      href={data.gbif_link}
                      className="m-2"
                      outline
                      color="primary"
                      icon
                    >
                      <Icon icon="it-external-link" padding={false} />
                      {intl.formatMessage(messages.viewGbifInfo)}
                    </Button>
                  )}

                  {data.biocase_link && (
                    <Button
                      tag={UniversalLink}
                      href={data.biocase_link}
                      className="m-2"
                      outline
                      color="primary"
                      icon
                    >
                      <Icon icon="it-download" padding={false} />
                      {intl.formatMessage(messages.downloadBiocaseFormat)}
                    </Button>
                  )}
                </div>

                <Images images={data.images} />
                <PollNetData data={data} />
                <Natura2000Sites source={data?.['@components']?.natura2000} />
                <Art12 source={data?.['@components']?.art12} />
                <Watchings data={data} />

                <CitizenScienceData data={data} />

                <ChildrenItems items={data.items} />
              </Container>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CatalogView;
