/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

const SDF1_7 = ({ data }) => {
  const intl = useIntl();
  moment.locale(intl.locale);
  return (
    <>
      <h2>1.7 Site indication and designation / classification dates</h2>
      <table className="SDFtable MinimalHeight">
        <tbody>
          <tr>
            <td width="22%" className="Bold">
              Date site proposed as SCI:
            </td>
            <td>
              {data.site_sci_prop_date
                ? moment(data.site_sci_prop_date).format('YYYY-MM')
                : 'No data'}
            </td>
          </tr>
          <tr>
            <td width="22%" className="Bold">
              Date site confirmed as SCI:
            </td>
            <td>
              {data.site_sci_conf_date
                ? moment(data.site_sci_conf_date).format('YYYY-MM')
                : 'No data'}
            </td>
          </tr>
          <tr>
            <td width="22%" className="Bold">
              Date site designated as SAC:
            </td>
            <td>2017-02</td>
          </tr>
          <tr>
            <td width="22%" className="DivisorRight Bold">
              National legal reference of SAC designation:
            </td>
            <td className="DivisorTop">{data.site_sac_legal_ref}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SDF1_7;
