/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { UniversalLink } from '@plone/volto/components';
//import { OSMMap } from 'volto-venue';

const SDF7 = ({ data }) => {
  return (
    <>
      <br />
      <table className="SDFtableNoBorder TableWidthAuto">
        <tbody>
          <tr>
            <td colspan="2">
              Map delivered as PDF in electronic format (optional)
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <table className="no-border-collapse">
                <tbody>
                  <tr>
                    <td style={{ width: '30px' }}>
                      <div className="CheckType">
                        {data.map_pdf === 1 ? 'X' : ''}
                      </div>
                    </td>
                    <td style={{ width: '30px' }}>Yes</td>
                    <td style={{ width: '30px' }}>
                      <div className="CheckType">
                        {data.map_pdf === 0 ? 'X' : ''}
                      </div>
                    </td>
                    <td style={{ width: '30px' }}>No</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <h2>SITE DISPLAY</h2>
      {data.geoviewer_url && (
        <UniversalLink href={data.geoviewer_url}>
          View on Geoviewer
        </UniversalLink>
      )}
      {/* <OSMMap
        markers={[
          {
            latitude: data.site_latitude,
            longitude: data.site_longitude,
          },
        ]}
        showTooltip={false}
        zoom={12}
      /> */}
    </>
  );
};

export default SDF7;
