/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDF2_5 = ({ data }) => {
  return (
    <>
      <h2>2.5 Administrative region code and name</h2>
      <table className="SDFtable3rd MinimalHeight">
        <tbody>
          <tr>
            <th width="22%">NUTS level 2 code</th>
            <th>Region Name</th>
          </tr>
          <tr>
            <td className="CellBorder">{data.region_code}</td>
            <td className="CellBorder">{data.region_name}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SDF2_5;
