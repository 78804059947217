import React from 'react';
import PropTypes from 'prop-types';

import Body from '@package/components/Blocks/SearchCatalog/Body';

const View = ({ data, id }) => {
  return (
    <div className="block full-width">
      <Body block={data} />
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
};

export default View;
