import { GET_SITI_NATURA_2000 } from '@package/actions';

const initialState = {
  // error: null,
  // hasError: false,
  // result: [],
  // loadingResults: false,
  // loaded: false,
  subrequests: {},
};

export const getSitiNatura2000Reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_SITI_NATURA_2000}_PENDING`:
      return {
        ...state,
        subrequests: {
          ...state.subrequests,
          [action.subrequest]: {
            ...(state.subrequests[action.subrequest] || {
              result: {
                items: [],
                items_total: 0,
                batching: {},
              },
            }),
            error: null,
            loaded: false,
            loadingResults: true,
          },
        },
      };

    case `${GET_SITI_NATURA_2000}_SUCCESS`:
      return {
        ...state,
        subrequests: {
          ...state.subrequests,
          [action.subrequest]: {
            result: action.result,
            loadingResults: false,
            loaded: true,
          },
        },
      };

    case `${GET_SITI_NATURA_2000}_FAIL`:
      return {
        ...state,
        subrequests: {
          ...state.subrequests,
          [action.subrequest]: {
            result: null,
            error: action.error,
            hasError: true,
            loadingResults: false,
            loaded: false,
          },
        },
      };

    default:
      return state;
  }
};
