/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDF6_1 = ({ data }) => {
  return (
    <>
      <h2>6.1 Body(ies) responsible for the site management:</h2>
      <table className="SDFtable MinimalHeight">
        <tbody>
          <tr>
            <td width="22%" className="Bold">
              Organisation:
            </td>
            <td className="CellBorderBottom">{data.mgmt_body_org}</td>
          </tr>
          <tr>
            <td width="22%" className="Bold">
              Address:
            </td>
            <td className="CellBorderBottom">{data.mgmt_body_address}</td>
          </tr>
          <tr>
            <td width="22%" className="Bold">
              Email:
            </td>
            <td className="CellBorderBottom">{data.mgmt_body_email}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SDF6_1;
