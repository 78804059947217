import React from 'react';
import { flattenToAppURL } from '@plone/volto/helpers';

const Background = (props) => {
  let image = props?.bgImage?.[0]?.image?.scales?.large?.download;

  image = image
    ? flattenToAppURL(image)
    : props?.bgImage?.[0]?.['@id'] + '/@@images/image/large';

  let style = {};
  if (image) {
    style.backgroundImage = `url('${image}')`;
  }

  return <div className="searchCatalog-background" style={style} />;
};

export default Background;
