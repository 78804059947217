import React from 'react';
import { viewValue, isEmpty } from '../utils';

const Art12_4_BreedingDistribution = ({ data }) => {
  const empty =
    isEmpty(data.sensitive_species) &&
    isEmpty(data.distribution_period) &&
    isEmpty(data.distribution_map) &&
    isEmpty(data.distribution_surface_area) &&
    isEmpty(data.distribution_method) &&
    isEmpty(data.additional_distribution_map) &&
    isEmpty(data.distribution_source) &&
    isEmpty(data.distribution_info);
  return !empty ? (
    <div className="art12-section">
      <h2>4. Breeding distribution map and size</h2>
      <table className="art12-table">
        <tbody>
          <tr>
            <th>4.1 Sensitive Species</th>
            <td>{viewValue(data.sensitive_species)}</td>
          </tr>
          <tr>
            <th>4.2 Year or Period</th>
            <td>{viewValue(data.distribution_period)}</td>
          </tr>
          <tr>
            <th>4.3 Breading distribution map</th>
            <td>{viewValue(data.distribution_map)}</td>
          </tr>
          <tr>
            <th>4.4 Breading Distribution surface area</th>
            <td>{viewValue(data.distribution_surface_area)}</td>
          </tr>
          <tr>
            <th>4.5 Breeding Distribution Method used</th>
            <td>{viewValue(data.distribution_method)}</td>
          </tr>
          <tr>
            <th>
              4.6 Additional maps<span className="right">(Optional)</span>
            </th>
            <td>{viewValue(data.additional_distribution_map)}</td>
          </tr>
          <tr>
            <th>4.7 Sources</th>
            <td>{viewValue(data.distribution_source)}</td>
          </tr>
          <tr>
            <th>
              4.8 Additional Information
              <span className="right">(Optional)</span>
            </th>
            <td className="highlited">{viewValue(data.distribution_info)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
};

export default Art12_4_BreedingDistribution;
