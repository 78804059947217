/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDF6_2 = ({ data }) => {
  return (
    <>
      <h2>6.2 Management Plan(s):</h2>
      <p className="more-margin">An actual management plan does exist:</p>
      <table className="SDFtable">
        <tbody>
          <tr>
            <td width="4%" className="top">
              <div className="CheckType">
                {data.mgmt_status === 'Y' ? 'X' : ''}
              </div>
            </td>
            <td width="10%" className="top">
              Yes
            </td>
            <td width="86%"></td>
          </tr>
          <tr>
            <td width="4%" className="top">
              <div className="CheckType">
                {data.mgmt_status !== 'N' && data.mgmt_status !== 'Y'
                  ? 'X'
                  : ''}
              </div>
            </td>
            <td colspan="2" className="top">
              No, but in preparation
            </td>
          </tr>
          <tr>
            <td width="4%" className="top">
              <div className="CheckType">
                {data.mgmt_status === 'N' ? 'X' : ''}
              </div>
            </td>
            <td colspan="2" className="top">
              No
            </td>
          </tr>
        </tbody>
      </table>

      <h2>6.3 Conservation measures (optional)</h2>
      <table className="SDFtable MinimalHeight">
        <tbody>
          <tr>
            <td>{data.mgmt_conserv_measures}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SDF6_2;
