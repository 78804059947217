/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import DataTable from 'react-data-table-component';

import { Spinner, Button } from 'design-react-kit/dist/design-react-kit';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from '@italia/components/ItaliaTheme';

import { getCitizenScienceData } from '@package/actions';
import ViewError from '@package/components/Views/Catalog/ViewError';
import { downloadFile } from '@package/components/Views/Catalog/Utils/DownloadFile';

const messages = defineMessages({
  CitizenScience: {
    id: 'CitizenScience_title',
    defaultMessage: 'Osservazioni da Citizen Science',
  },
  id: {
    id: 'CitizenScience_id',
    defaultMessage: 'ID Osservazione',
  },
  canonicalName: {
    id: 'CitizenScience_canonicalName',
    defaultMessage: 'Nome scientifico',
  },
  year: {
    id: 'CitizenScience_year',
    defaultMessage: 'Anno di osservazione',
  },

  latitude: {
    id: 'CitizenScience_latitude',
    defaultMessage: 'Lat',
  },
  longitude: {
    id: 'CitizenScience_longitude',
    defaultMessage: 'Lon',
  },
  datasource: {
    id: 'CitizenScience_datasource',
    defaultMessage: 'Fonte dati',
  },
  no_data: {
    id: 'CitizenScience_nodata',
    defaultMessage: 'Nessun dato trovato',
  },
  downloadCSV: {
    id: 'CitizenScience_downloadCSV',
    defaultMessage: 'Download dei dati visualizzati',
  },
  exportPDF: {
    id: 'CitizenScience_exportPDF',
    defaultMessage: 'Crea PDF',
  },
  loading: {
    id: 'CitizenScience_loading',
    defaultMessage: 'Sto caricando i dati da Citizen Science...',
  },
  filtra_per: {
    id: 'CitizenScience_filtraPer',
    defaultMessage: 'Filtra per',
  },
  max_items_info: {
    id: 'CitizenScience_max_items_info',
    defaultMessage: 'Il numero massimo di elementi è limitato a 10.000 record.',
  },
});

const CitizenScience = ({ data }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const citizenScienceUrl = data?.['@components']?.citizenscience;
  const DEFAULT_SORT_ON = 'canonicalname';
  const DEFAULT_SORT_ORDER = 'ascending';
  const b_size = 10;

  const [sort_on, setSort_on] = useState(DEFAULT_SORT_ON);
  const [sort_order, setSort_order] = useState(DEFAULT_SORT_ORDER);
  const [downloadCSVStatus, setDownloadCSVStatus] = useState({});
  const [downloadPDFStatus, setDownloadPDFStatus] = useState({});
  const [filters, setFilters] = useState({});

  const citizenScience = useSelector((state) => {
    return state.citizenScienceData;
  });

  const doRequest = (b_start = 0) => {
    const filtersString = Object.keys(filters)
      .map(function (key) {
        return key + '=' + encodeURIComponent(filters[key]);
      })
      .join('&');

    dispatch(
      getCitizenScienceData(
        flattenToAppURL(citizenScienceUrl) +
          '&b_start=' +
          b_start +
          '&sort_on=' +
          sort_on +
          '&sort_order=' +
          sort_order +
          '&b_size=' +
          b_size +
          (filtersString ? '&' + filtersString : ''),
      ),
    );
  };

  useEffect(() => {
    if (
      citizenScienceUrl &&
      !citizenScience.loading &&
      !citizenScience.loaded
    ) {
      doRequest();
    }
  }, [citizenScienceUrl]);

  useEffect(() => {
    if (citizenScience.loaded) {
      doRequest();
    }
  }, [sort_on, sort_order, filters]);

  const onChangeFilter = (e, column) => {
    setFilters({ ...filters, [column.selector]: e.target.value });
  };

  const drawCell = (row, index, column, id, resultCell) => {
    if (row.id === 'filters-row') {
      return citizenScience?.result?.filters?.[column.selector]?.length > 0 ? (
        <>
          <select
            onBlur={() => {}}
            onChange={(e) => onChangeFilter(e, column)}
            value={filters[column.selector] ?? null}
            aria-label={
              intl.formatMessage(messages.filtra_per) + ' ' + column.name
            }
          >
            <option value="" hidden>
              {intl.formatMessage(messages.filtra_per) + ' ' + column.name}
            </option>
            <option value=""></option>
            {citizenScience?.result.filters[column.selector]
              .filter((o) => o.value != null)
              .map((o) => (
                <option key={o.value} value={o.value}>
                  {o.label}
                </option>
              ))}
          </select>
        </>
      ) : null;
    }
    return resultCell || <>{row[column.selector]}</>;
  };

  const columns = [
    {
      name: intl.formatMessage(messages.id),
      selector: 'id',
      sortable: true,
      cell: drawCell,
      width: '100px',
    },
    {
      name: intl.formatMessage(messages.canonicalName),
      selector: 'canonicalname',
      sortable: true,
      cell: drawCell,
      //width: '200px',
    },
    {
      name: intl.formatMessage(messages.year),
      selector: 'year',
      sortable: true,
      cell: drawCell,
      width: '100px',
    },
    {
      name: intl.formatMessage(messages.latitude),
      selector: 'lat',
      sortable: true,
      width: '150px',
    },
    {
      name: intl.formatMessage(messages.longitude),
      selector: 'lon',
      sortable: true,
      width: '150px',
    },
    {
      name: intl.formatMessage(messages.datasource),
      selector: 'datasource',
      sortable: true,
      cell: (row, index, column, id) =>
        drawCell(
          row,
          index,
          column,
          id,
          <div dangerouslySetInnerHTML={{ __html: row.datasource }} />,
        ),

      //  width: '150px',
    },
  ];

  const handlePageChange = (page) => {
    const b_start = (page - 1) * b_size;
    doRequest(b_start);
  };
  const setSorting = (column, order) => {
    setSort_on(column);
    setSort_order(order === 'asc' ? 'ascending' : 'descending');
  };

  const downloadCSV = () => {
    downloadFile(
      '/@citizenscience-csv',
      { name: data.canonicalName },
      intl,
      (status) => {
        setDownloadCSVStatus(status);
      },
    );
  };
  const downloadPDF = () => {
    downloadFile(
      '/@citizenscience-pdf',
      { name: data.canonicalName },
      intl,
      (status) => {
        setDownloadPDFStatus(status);
      },
    );
  };

  return citizenScienceUrl ? (
    <div className="citizenScience mt-3 mb-5">
      <h3>{intl.formatMessage(messages.CitizenScience)}</h3>

      {citizenScience.loadingResults && (
        <div className="mt-5 mb-5 loading">
          <Spinner active double={false} small={false} tag="span" />
          <div>{intl.formatMessage(messages.loading)}</div>
        </div>
      )}

      {citizenScience.result?.items_total > 0 ? (
        <>
          {!citizenScience.loadingResults && (
            <>
              <div className="d-flex">
                <Button
                  color="light"
                  size="xs"
                  className="m-2 d-flex"
                  onClick={() => downloadCSV()}
                  disabled={downloadCSVStatus.loading}
                  icon
                >
                  {downloadCSVStatus.loading && (
                    <Spinner
                      active
                      double={false}
                      tag="span"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  <Icon icon="it-download" padding={false} />
                  {intl.formatMessage(messages.downloadCSV)}
                </Button>

                <Button
                  color="light"
                  size="xs"
                  className="m-2 d-flex"
                  onClick={() => downloadPDF()}
                  disabled={downloadPDFStatus.loading}
                  icon
                >
                  {downloadPDFStatus.loading && (
                    <Spinner
                      active
                      double={false}
                      tag="span"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  <Icon icon="far file-pdf" padding={false} />
                  {intl.formatMessage(messages.exportPDF)}
                </Button>
              </div>
              <div className="info-text">
                {intl.formatMessage(messages.max_items_info)}
              </div>
            </>
          )}

          {downloadPDFStatus.error && (
            <ViewError error={downloadPDFStatus.error} />
          )}
          {downloadCSVStatus.error && (
            <ViewError error={downloadPDFStatus.error} />
          )}
          <DataTable
            columns={columns}
            data={
              citizenScience?.result?.items
                ? [{ id: 'filters-row' }, ...citizenScience.result.items]
                : []
            }
            striped={true}
            highlightOnHover={true}
            pointerOnHover={false}
            noDataComponent={intl.formatMessage(messages.no_data)}
            noHeader={true}
            responsive={true}
            defaultSortField={DEFAULT_SORT_ON}
            defaultSortAsc={DEFAULT_SORT_ORDER === 'ascending'}
            pagination={true}
            paginationComponentOptions={{ noRowsPerPage: true }}
            paginationPerPage={b_size}
            paginationServer={true}
            paginationServerOptions={{
              persistSelectedOnPageChange: true,
              persistSelectedOnSort: false,
            }}
            paginationTotalRows={citizenScience.result.items_total}
            onChangePage={handlePageChange}
            progressPending={citizenScience.loadingResults}
            progressComponent={() => <></>}
            sortServer={true}
            onSort={(column, direction) =>
              setSorting(column.selector, direction)
            }
            // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            // subHeader
            // subHeaderComponent={SubHeaderComponent}
          />
        </>
      ) : (
        !citizenScience.loadingResults && (
          <div>{intl.formatMessage(messages.no_data)}</div>
        )
      )}
    </div>
  ) : null;
};

export default CitizenScience;
