import { GET_NATURA2000_SDF } from '@package/actions';

const initialState = {
  error: null,
  hasError: false,
  result: [],
  loadingResults: false,
  loaded: false,
};

export const getNatura2000SDFReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_NATURA2000_SDF}_PENDING`:
      return {
        ...state,
        loadingResults: true,
        loaded: false,
      };

    case `${GET_NATURA2000_SDF}_SUCCESS`:
      return {
        ...state,
        result: action.result,
        loadingResults: false,
        loaded: true,
      };

    case `${GET_NATURA2000_SDF}_FAIL`:
      return {
        ...state,
        error: action.error,
        hasError: true,
        loadingResults: false,
        loaded: false,
        result: [],
      };

    default:
      return state;
  }
};
