export const SEARCH_AUTOCOMPLETE = 'SEARCH_AUTOCOMPLETE';

export function searchAutocomplete(text = '') {
  return {
    type: SEARCH_AUTOCOMPLETE,
    request: {
      op: 'get',
      path: `/@search-autocomplete`,
      params: { name: text },
    },
  };
}
