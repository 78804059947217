/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDFTitle = ({ data }) => {
  return (
    <div className="siteData_title">
      <table className="noborder">
        <tbody>
          <tr>
            <td width="15%">SITE</td>
            <th>{data.site_code}</th>
          </tr>
          <tr>
            <td>SITENAME</td>
            <th>{data.site_name}</th>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SDFTitle;
