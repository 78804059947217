/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDF5_1 = ({ data }) => {
  return (
    <>
      <h2>5.1 Designation types at national and regional level:</h2>
      <table className="SDFtable3rd no-border-collapse MinimalHeight">
        <tbody>
          <tr>
            <th width="22%">Code</th>
            <th>Cover [%]</th>
          </tr>
          {data.national?.map((n) => (
            <tr key={n.national_dtype_code}>
              <td className="DivisorTop DivisorRight">
                {n.national_dtype_code}
              </td>
              <td className="DivisorTop">
                {Number(n.national_dtype_cover || 0).toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default SDF5_1;
