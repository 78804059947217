import React from 'react';
import { viewValue, isEmpty } from '../utils';

const Art12_6_ProgressInWork = ({ data }) => {
  const empty =
    isEmpty(data.plan) &&
    isEmpty(data.national_plan_adopted) &&
    isEmpty(data.measures_taken) &&
    isEmpty(data.effectiveness_SAPs) &&
    isEmpty(data.effectiveness_MPs) &&
    isEmpty(data.further_info);
  return !empty ? (
    <div className="art12-section">
      <h2>
        6. Progress in work related to international Species Action Plans
        (SAPs), Management Plans (MPs) and Brief Management Statements (BMSs)
      </h2>
      <table className="art12-table">
        <tbody>
          <tr>
            <th>6.1 Type of international plan</th>
            <td>{viewValue(data.plan)}</td>
          </tr>
          <tr>
            <th>
              6.2 Has a national plan linked to the international SAP/MP/BMS
              been adopted?
            </th>
            <td>{viewValue(data.national_plan_adopted)}</td>
          </tr>
          <tr>
            <th>
              6.3 If 'NO', describe any measures and initiatives taken related
              to the international SAP/MP/BMS
            </th>
            <td>{viewValue(data.measures_taken)}</td>
          </tr>
          <tr>
            <th>
              6.4 Assessment of the effectiveness of SAPs for globally
              threatened species (Art. 12, Species Action Plans)
            </th>
            <td>{viewValue(data.effectiveness_SAPs)}</td>
          </tr>
          <tr>
            <th>
              6.5 Assessment of the effectiveness of MPs for huntable species in
              non-Secure status (Articles 3 and 7, Management Plans)
            </th>
            <td>{viewValue(data.effectiveness_MPs)}</td>
          </tr>
          <tr>
            <th>6.6 Sources of further information</th>
            <td>{viewValue(data.further_info)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
};

export default Art12_6_ProgressInWork;
