/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDF4_1 = ({ data }) => {
  return (
    <>
      <h2>4.1 General site character</h2>
      <table className="SDFtable3rd no-border-collapse MinimalHeight">
        <tbody>
          <tr>
            <th width="22%">Habitat class</th>
            <th>% Cover</th>
          </tr>
          {data.habitat_class?.map((hc) => (
            <tr key={hc.habitat_class_code}>
              <td className="DivisorTop DivisorRight">
                {hc.habitat_class_code}
              </td>
              <td className="DivisorTop">{hc.habitat_class_cover}</td>
            </tr>
          ))}
          <tr>
            <th className="DivisorTop DivisorRight">
              <h4>Total Habitat Cover</h4>
            </th>
            <td className="DivisorTop">
              {data.habitat_class?.reduce(function (a, b) {
                return a + b.habitat_class_cover;
              }, 0)}
            </td>
          </tr>
        </tbody>
      </table>

      {data.site_characteristics && (
        <>
          <h3 className="more-margin">Other Site Characteristics</h3>
          <table className="SDFtable MinimalHeight">
            <tbody>
              <tr>
                <td>{data.site_characteristics}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </>
  );
};

export default SDF4_1;
