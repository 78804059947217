/**
 * HeaderSlim component.
 * @module components/ItaliaTheme/Header/HeaderSlim
 */

/* CUSTOMIZATIONS:
   - added HeaderSearch (moved from HeaderCenter to here)
*/

import React from 'react';
import { useSelector } from 'react-redux';

import {
  ParentSiteMenu,
  LanguageSelector,
  ArLogin,
  HeaderSearch,
  TertiaryMenu,
} from '@italia/components/ItaliaTheme';

const HeaderSlimRightZone = () => {
  const subsite = useSelector((state) => state.subsite?.data);

  return (
    <>
      <ParentSiteMenu />

      {!subsite && <TertiaryMenu />}

      <HeaderSearch />
      <LanguageSelector />
      <ArLogin />
    </>
  );
};

export default HeaderSlimRightZone;
