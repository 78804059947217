/**
 * FooterNavigation components.
 * @module components/ItaliaTheme/Footer/FooterNavigation
 */

import React from 'react';

const FooterNavigation = () => {
  return <></>;
};

export default FooterNavigation;
