/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const SDF2_3 = ({ data }) => {
  return (
    <>
      <h2>2.3 Marine area [%]</h2>
      <table className="SDFtable MinimalHeight">
        <tbody>
          <tr>
            <td>{Number(data.site_marine_area || 0).toFixed(4)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SDF2_3;
